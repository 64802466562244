@charset "UTF-8";

@font-face {
  font-family: "DGPIcons";
  src: url("../fonts/DGPIcons-Regular.woff") format("woff");
}

@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-rg.woff") format("woff");
}

@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-bd.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-md.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-xlt.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-lt.woff") format("woff");
  font-weight: 200;
}

@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-ltit.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "BlissProReg";
  src: url("../fonts/blsw-rg.woff") format("woff");
}

@font-face {
  font-family: "BlissProBold";
  src: url("../fonts/blsw-bd.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "BlissProMedium";
  src: url("../fonts/blsw-md.woff") format("woff");
}

@font-face {
  font-family: "BlissProExtraLight";
  src: url("../fonts/blsw-xlt.woff") format("woff");
}

@font-face {
  font-family: "BlissProLight";
  src: url("../fonts/blsw-lt.woff") format("woff");
}

@font-face {
  font-family: "BlissProLightItalic";
  src: url("../fonts/blsw-ltit.woff") format("woff");
}

@font-face {
  font-family: "Zukunft-Icons";
  src: url("../fonts/DGPZukunft-Regular.woff") format("woff");
}

.pflegepraktikum-background-color,
.famulatur-background-color,
.praktisches-jahr-background-color,
.assistent\/assistentin-in-weiterbildung-background-color,
.oberarzt\/ärztin-background-color,
.chefarzt\/ärztin-background-color,
.facharzt\/ärztin-background-color,
.wissenschaftliche-mitarbeitende-background-color,
.atmungstherapeuten-background-color,
.sonstige-background-color,
.landesärztekammer-background-color,
.weiterbildungsbefugte-background-color {
  color: white;
  padding: 2px 5px 0px 5px !important;
  background-color: black;
  margin: 5px 5px 5px 0px !important;
  height: 22px;
  white-space: nowrap;
}

.pflegepraktikum-background-color {
  background-color: #00AF9B;
}

.famulatur-background-color {
  background-color: #64BEC3;
}

.praktisches-jahr-background-color {
  background-color: #336A73;
}

.assistent\/assistentin-in-weiterbildung-background-color {
  background-color: #ED193A;
}

.oberarzt\/ärztin-background-color {
  background-color: #436A97;
}

.chefarzt\/ärztin-background-color {
  background-color: #053875;
}

.facharzt\/ärztin-background-color {
  background-color: #819BB9;
}

.wissenschaftliche-mitarbeitende-background-color {
  background-color: #7C7C7C;
}

.atmungstherapeuten-background-color {
  background-color: #3E3E3E;
}

.sonstige-background-color {
  background-color: #C1C1C1;
  color: black !important;
}

.landesärztekammer-background-color {
  color: black !important;
  background-color: white;
  border: 1px solid black;
}

.weiterbildungsbefugte-background-color {
  background-color: #F26821;
}

.pflegepraktikum-color {
  color: #00AF9B;
}

.famulatur-color {
  color: #64BEC3;
}

.praktisches-jahr-color {
  color: #336A73;
}

.assistent\/assistentin-in-weiterbildung-color {
  color: #ED193A;
}

.oberarzt\/ärztin-color {
  color: #436A97;
}

.chefarzt\/ärztin-color {
  color: #053875;
}

.facharzt\/ärztin-color {
  color: #819BB9;
}

.wissenschaftliche-mitarbeitende-color {
  color: #7C7C7C;
}

.atmungstherapeuten-color {
  color: #3E3E3E;
}

.sonstige-color {
  color: #C1C1C1;
}

.weiterbildungsbefugte-color {
  color: #F26821;
}

.orange {
  color: #d47143 !important;
}

.teal {
  color: #05454f !important;
}

.blue {
  color: #053875 !important;
}

.light_green {
  color: #00af9b !important;
}

.grey {
  color: #3e3e3e !important;
}

html,
body {
  font-family: 'BlissProLight';
  font-size: 18px;
  color: black;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  margin: 0;
  padding: 0;
  line-height: 1.4em;
}

h1 {
  font-family: 'BlissProLightItalic';
  font-weight: normal;
  font-size: 33px;
  color: #004550;
  letter-spacing: .01em;
}

h2 {
  font-family: 'BlissProExtraLight';
  font-weight: normal;
  font-size: 26px;
  color: #3e3e3e;
  text-transform: uppercase;
  letter-spacing: .01em;
}

h3 {
  font-family: 'BlissProLightItalic';
  font-size: 24px;
  color: #3e3e3e;
  font-weight: normal;
}

h4 {
  font-family: 'BlissProMedium';
  color: #3e3e3e;
  font-size: 23px;
  padding-top: 10px;
  font-weight: normal;
}

h5 {
  font-family: 'BlissProLight';
  font-size: 20px;
  color: #004550;
  font-weight: normal;
}

h6 {
  font-family: 'BlissProLight';
  font-size: 20px;
  color: #006B7F;
  font-weight: normal;
}

h7 {
  font-family: 'BlissProMedium';
  color: #3e3e3e;
  font-size: 15px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}

a {
  text-decoration: none;
  color: #00af9b;
}

p {
  font-size: 18px;
  margin-top: 0;
  line-height: 1.4em;
  color: #3e3e3e;
}

input,
button {
  font-family: 'BlissProReg';
  color: #3e3e3e;
  border-radius: 0;
}

strong {
  font-family: BlissProBold;
  font-weight: normal;
}

em {
  font-family: 'BlissProLightItalic';
  font-style: normal;
  line-height: 1.4em;
}

ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 0px;
  line-height: 1.4em;
}

li {
  padding-bottom: 5px;
  color: #3e3e3e;
}

ol {
  list-style: none;
  padding-left: 0px;
  line-height: 1.4em;
}

b {
  font-family: BlissPro;
  font-weight: unset;
}

textarea {
  color: #3e3e3e;
  border-radius: 0;
}

span {
  color: #3e3e3e;
}

label {
  color: #3e3e3e;
}

.cke_editable {
  padding: 10px;
}

.cke_editable p {
  font-family: 'BlissProLight' !important;
}

.cke_editable ul,
.cke_editable ol {
  padding-left: 10px;
}

.cke_editable ul li:before {
  content: "\2022";
  color: #00af9b;
  font-weight: bold;
  margin-right: 5px;
}

.cke_editable ol {
  list-style: none;
  counter-reset: li;
}

.cke_editable ol li {
  counter-increment: li;
}

.cke_editable ol li:before {
  content: counter(li) ".";
  color: #00af9b;
  margin-right: 5px;
}

.cke_notifications_area {
  display: none !important;
}

