@font-face {
  font-family: "DGPIcons";
  src: url("../fonts/DGPIcons-Regular.woff") format("woff");
}
@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-rg.woff") format("woff");
}
@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-bd.woff") format("woff");
  font-weight: bold;
}
@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-md.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-xlt.woff") format("woff");
  font-weight: 100;
}
@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-lt.woff") format("woff");
  font-weight: 200;
}
@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-ltit.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "BlissProReg";
  src: url("../fonts/blsw-rg.woff") format("woff");
}
@font-face {
  font-family: "BlissProBold";
  src: url("../fonts/blsw-bd.woff") format("woff");
  font-weight: normal;
}
@font-face {
  font-family: "BlissProMedium";
  src: url("../fonts/blsw-md.woff") format("woff");
}
@font-face {
  font-family: "BlissProExtraLight";
  src: url("../fonts/blsw-xlt.woff") format("woff");
}
@font-face {
  font-family: "BlissProLight";
  src: url("../fonts/blsw-lt.woff") format("woff");
}
@font-face {
  font-family: "BlissProLightItalic";
  src: url("../fonts/blsw-ltit.woff") format("woff");
}
@font-face {
  font-family: "Zukunft-Icons";
  src: url("../fonts/DGPZukunft-Regular.woff") format("woff");
}