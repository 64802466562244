html, body {
  font-family: 'BlissProLight';
  font-size: 18px;
  color: black;
  margin: 0;
  padding: 0;
//  line-height: 1.4em;
}

html {
  height: 100%;
}

body {
  height: 100%;
}
p,h1,h2,h3,h4,h5,h6,h7 {
  margin: 0;
  padding: 0;
  line-height: 1.4em;
}

h1 {
  font-family: 'BlissProLightItalic';
  font-weight: normal;
  font-size: 33px;
  color: $dark_green;
  letter-spacing: .01em
}

h2 {
  font-family: 'BlissProExtraLight';
  font-weight: normal;
  font-size: 26px;
  color: $grey;
  text-transform: uppercase;
  letter-spacing: .01em;
}

h3 {
  font-family: 'BlissProLightItalic';
  font-size: 24px;
  color: $grey;
  font-weight: normal;
}

h4 {
  font-family: 'BlissProMedium';
  color: $grey;
  font-size: 23px;
  padding-top: 10px;
  font-weight: normal;
}

h5 {
  font-family: 'BlissProLight';
  font-size: 20px;
  color: $dark_green;
  font-weight: normal;
}

h6 {
  font-family: 'BlissProLight';
  font-size: 20px;
  color: $medium_green;
  font-weight: normal;
}

h7 {
  font-family: 'BlissProMedium';
  color: $grey;
  font-size: 15px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}

a {
  text-decoration: none;
  color: $light_green;
}

p {
  font-size: 18px;
  margin-top: 0;
  line-height: 1.4em;
  color: $grey;
}

input,button {
  font-family: 'BlissProReg';
  color: $grey;
  border-radius: 0;
}

strong {
  font-family: BlissProBold;
  font-weight: normal;
}

em {
  font-family: 'BlissProLightItalic';
  font-style: normal;
  line-height: 1.4em;
}

ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 0px;
  line-height: 1.4em;
}
li {
  padding-bottom: 5px;
  color: $grey;
}
ol {
  list-style: none;
  padding-left: 0px;
  line-height: 1.4em;
}

b {
  font-family: BlissPro;
  font-weight: unset;
}
textarea {
  color: $grey;
  border-radius: 0;
}
span {
  color: $grey;
}
label {
  color: $grey;
}