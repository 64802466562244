input[type="text"], .dropdown{
	font-size: 20px;
	height: 40px;
	width: 100%;
	border: solid 1px #00af9b;
	box-sizing: border-box;
	margin-top: 5px;
}

input[type="radio"], input[type="checkbox"]{
	-webkit-appearance: checkbox; /* Chrome, Safari, Opera */
	-moz-appearance: checkbox;    /* Firefox */
	-ms-appearance: checkbox;     /* not currently supported */
	margin: 0 10px 10px 0;
}

/* Checkbox and Radio Buttons */

input[type="radio"] {
	display: none;
	//position: absolute;
	//width: 16px;
	//height: 16px;
	//margin: 0;
	//border: 1px solid transparent;
	//margin-top: 3px;
}
input[type="radio"]:checked + label span {
	background: url('../logos/radiobutton-on.svg') #fff;
	background-repeat: no-repeat;
	background-position: center;
	height: 15px;
	width: 15px;
	display: inline-block;
	padding: 0;
	margin-right: 10px
}
input[type="radio"] + label span {
	background: url('../logos/radiobutton-off.svg') #fff;
	height: 15px;
	width: 15px;
	display: inline-block;
	padding: 0;
	margin-bottom: 0;
	margin-right: 10px;
}

input[type="checkbox"] {
	display: none;
	//position: absolute;
	//width: 16px;
	//height: 16px;
	//margin: 0;
	//border: 1px solid transparent;
	//margin-top: 3px;
}
input[type="checkbox"]:checked + label span {
	background: url('../logos/checkbox-checked.svg') #fff;
	background-repeat: no-repeat;
	background-position: center;
	height: 14px;
	width: 14px;
	display: inline-block;
	padding: 0;
	margin-right: 10px;
}
input[type="checkbox"] + label span {
	background: url('../logos/checkbox-unchecked.svg') #fff;
	height: 14px;
	width: 14px;
	display: inline-block;
	padding: 0;
	margin-bottom: 0;
	margin-right: 10px;
}

textarea {
	margin-top: 5px;
}

input:focus {

}

/* ------------------------------------ */

#sliders-checkout-root .rc-slider {
	margin-top: 15px;
}
#sliders-checkout-root .slider-men {
	margin-top: 20px !important;
}