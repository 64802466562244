/* Variable */
$second_level_page_padding_top: 50px;
$third_level_page_padding_top: 50px;
$third_level_page_padding_bottom: 90px;
$page_content_padding_lr: 30px;

.half_column {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  //overflow: scroll;
}

#content.anmeldung {
  padding-bottom: $third_level_page_padding_bottom;
}

#content:not(.anmeldung) {
  .half_column:nth-child(1) {
    padding-top: $third_level_page_padding_top;
    box-sizing: border-box;
    overflow: scroll;
    display: inline-block;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
  }

  .half_column:nth-child(2) {
    .half_column:nth-child(1) {
      overflow: hidden;
      padding-top: $third_level_page_padding_top;
    }
    .half_column:nth-child(2) {
      padding-top: $third_level_page_padding_top;
      box-sizing: border-box;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }
  .three_quarter_column {
    padding-top: $third_level_page_padding_top;
    box-sizing: border-box;
  }
  .quarter_column {
    padding-top: $third_level_page_padding_top;
    box-sizing: border-box;
  }
  .quarter_column, .three_quarter_column, .half_column {
    &:after {
      content:'';
      display: block;
      padding-bottom: $third_level_page_padding_bottom;
    }
  }
}

.third_column {
  position: relative;
  float: left;
  width: 33%;
  min-height: 100%;
}

.quarter_column {
  position: relative;
  float: left;
  width: 25%;
  height: 100%;
//  overflow: scroll;
}

.three_quarter_column {
  position: relative;
  float: left;
  width: 75%;
  height: 100%;
 // overflow: scroll;
}

#content {
  height: calc(100% - 66px);
}


#p17 {
  .quarter_column {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .three_quarter_column {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

#p5 {
}

.container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  height: 100%
}

.wrapper {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.wrap {
  overflow: auto;
}

.padded {
  padding: 0px 20px 0px 20px;
  overflow: auto;
}
.pad {
  padding-bottom: 15px;
}
.filled {
  padding: 0px 30px 0px 30px;
  overflow: auto;
}

.position_relative {
  position: relative;
  height: 100%;
}
.stick_bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.left {
  float: left;
}

.right {
  float: right;
}
.inline-block {
  > * {
    display: inline-block;
  }
}
.block {
  > *:not(script) {
    display: block;
  }
}
.clear {
  clear: both;
}
.underline {
  text-decoration: underline;
}
.underline:hover {
  text-decoration: none;
}
.xs_width {
  max-width: 574px;
}
.s_width {
  max-width: 630px;
}
.l_width {
  max-width: 736px;
}

.right_col_top_box {
  padding-bottom: 50px;
  > h7 {
    padding-bottom: 15px;
    display: block;
  }
  a:hover h3 {
    color: $dark_green;
  }
}

.anmeldung {
  padding-top: $third_level_page_padding_top;
  .quarter_column {
    padding-top: 35px;
    &:nth-child(5) {
      padding-top: 0px !important;
    }
  }
}

%offer-icon {
  font-family: 'Zukunft-Icons';
  padding-right: 3px;
  display:inline-block;
}
%flexed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.flex-container-center {
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-icon:before {
  @extend %offer-icon;
  content: '\1F589';
}
.duplicate-icon:before {
  @extend %offer-icon;
  content: url('../logos/duplizieren.svg');
}
.delete-icon:before {
  @extend %offer-icon;
  content: url('../logos/loeschen.svg');
}
.delete-icon {
  a {
    font-size: 18px;
  }
}
.public-icon:before {
  @extend %offer-icon;
  content: '\1F517';
}

%flash {
  cursor: pointer;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 100;
}
.flash-success {
  @extend %flash;
  background-color: $light_green;
}
.flash-warning {
  @extend %flash;
  background-color: $pink_error;
}
.flash-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    display: inline-block;
    vertical-align: text-bottom;
    color: white;
  }
  a {
    margin-left: 10px;
    padding-top: 20px;
    display: inline-block;
  }

}

.right_arrow:after {
  font-family: 'Zukunft-Icons';
  content: '\2009\2192';
}
.dark-green-hover {
  &:hover {
    color: $dark_green !important;
  }
}

.registration-button {
  display: inline-block;
  padding: 10px 15px 10px 15px;
  margin-top: 10px;
  background-color: white;
  span {
    color: $dark_light_green;
    &:hover {
      color: white;
    }
  }
  &:hover {
    background-color: $dark_green;
    span {
      color: white;
    }
  }
}