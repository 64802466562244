@import './_fonts';
@import './_colors';
@import './_tags';
@import './_inputs';
@import './_toggle';
@import './_form_layout';
@import './_frontend_layout';
@import './_frontend_table';
@import './sizes';
@import './frontend_resize';



#formlocked > input {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;       
}
.city_autocomplete {
    font-size: 16px;
    //position: relative;
  }
.xdsoft_autocomplete {
  position: relative;
  width: auto !important;
  input {
    padding-left: 10px;
  }
}
.xdsoft_autocomplete_hint {
  display: none !important;
}
.xdsoft_autocomplete_dropdown {
  max-height: 200px !important;
  overflow: scroll;
  border: 1px solid $light_green;
  border-top: none;
  box-sizing: border-box;
  background-color: white;
  position: absolute;
  top: 40px;
  left: 0px;
  bottom: auto;
  z-index: 100;
  display: none;
  > div {
    font-size: 20px;
    line-height: 40px;
  }
}
.invalid_input + .xdsoft_autocomplete_dropdown {
  border: 1px solid $red_error;
  border-top: none;
}

.not_allowed {
  border-color: $red_error;
}
/* Form Variable */

%form_submit_button {
  font-family: 'BlissProLight';
  font-weight: normal;
  font-size: 19px;
  height: 40px;
  //max-width: 168px;
  //width: 100%;
  width: 168px;
  background-color: #00af9b;
  color: white;
  margin-top: 10px;
  border: none;
  cursor: pointer;
}

%form_text_input {
  font-size: 20px;
  height: 40px;
  width: 100%;
  border: solid 1px #00af9b;
  box-sizing: border-box;
  background-color: white;
  color: black;
  padding-left: 10px;
  margin-top: 10px;
}





/* Default */

.hidden {
  display: none !important;
  //visibility: hidden;
}

.invisible {
  //visibility: hidden;
  display: none !important;
}
.no-scroll {
  overflow: visible;
}
.isChecked, .isNotChecked {
  text-indent: -19px !important;
  padding-left: 19px !important;
  box-sizing: border-box;
}
.isChecked:before {
  font-family: 'Zukunft-Icons';
  content: 'â';
  color: #77CE52;
  padding-right: 3px;
}
.isNotChecked:before {
  font-family: 'Zukunft-Icons';
  content: 'â®¾';
  color: #ED193A;
  padding-right: 3px;
}
.history-back {
  margin-bottom: 30px;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  &:before {
    font-family: 'Zukunft-Icons';
    content: '\2190\2009';
  }
  &:hover {
    text-decoration: none;
  }
}
.skip {
  margin-bottom: 30px;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  &:after {
    font-family: 'Zukunft-Icons';
    content: "\2192";
  }
  &:hover {
    text-decoration: none;
  }
}
.button_box {
  color: white;
  background-color: $light_green;
  padding: 10px 20px 10px 20px;
  margin: 5px 0px 10px 0px;
}

/* -------------------------- */

/* Color Frontend Layout */

.infocenter {
  .current_page {
    color: $light_green !important;
  }
  > div:last-child, .mobile_header {
    background-color: $teal;
    color: white;
  }
  #shortcut_list a:hover {
    color: $light_green !important;
  }
  #menu2 a h3:hover {
    color: $light_green !important;
  }
}
.unterstuetzung_der_dgp {
  .current_page {
    color: $medium_green !important;
  }
  > div:last-child, .mobile_header {
    background-color: $orange;
    color: white;
  }
  #shortcut_list a:hover {
    color: $medium_green !important;
  }
  #menu2 a h3:hover {
    color: $medium_green !important;
  }
}
.fuer_partner {
  .current_page {
    color: $dark_green !important;
  }
  > div:last-child, .mobile_header {
    background-color: $dark_light_green;
    color: white;
  }
  #shortcut_list a:hover {
    color: $dark_green !important;
  }
  #menu2 a h3:hover {
    color: $dark_green !important;
  }
}
.nachwuchs {
  .current_page {
   color: $light_green !important;
  }
  > div:last-child, .mobile_header {
    background-color: $beige;
    color: $grey;
  }
  #menu2 li a h3 {
    color: $grey
  }
  #menu2 a h3:hover {
    color: $light_green !important;
  }
}

.white {
  color: white;
}

.black {
  color: black;
}

/*ckeditor remove tab*/
#p16 {
  .cke_dialog_tab[title="Target"] {
    display: none;
  }
}


/* ------------------------- */

/* Menu Elements */

.submenu {
  overflow: visible;
  position: relative;
  box-sizing: border-box;
  line-height: 1.6em;

  > p {
    margin: 0;
  }

  > h2 {
    color: white;
    padding-bottom: 20px;
  }
  h3 {
    font-size: 24px !important;
  }

}
#menu2 {
  padding: 0px;
  margin: 0px;

  li:not(:last-child) {
    margin-bottom: 5px;
  }

  li a h3 {
    color: white;
    font-size: 30px;
  }
}

#open_menu_container {
  float: right;
  line-height: 42px;
}
#close_menu_container {
  float: right;
}

.menu {
  z-index: 2000;
  background-color: white;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  display: flex;
  flex-direction: column;

  .menu_container {
    padding: 70px 30px 10px 30px;
    height: auto;
    //position: absolute;
    width: 100%;
    box-sizing: border-box;
    //overflow: hidden;
    flex: auto;

    li:not(:last-child), h2 {
      margin-bottom: 11px;
    }
    h3 {
      font-size: 24px !important;
      color: black !important;
    }
    h3:hover {
      color: $light_green !important;
    }
  }

  #footer {
    position: initial;
    li:last-child {
      display: none;
    }
  }
}

.wrapper .menu_bar {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: rgba(226,235,238,0.5)
}

.menu_bar {
  padding: 10px $page_content_padding_lr 10px $page_content_padding_lr;

  > * {
    display: inline-block;
  }
}

.menu_title {
  max-width: 350px;
  a {
    text-decoration: none;
    color: black;
  }
  a > * {
    display: inline-block;
    vertical-align: middle;
  }
  img {
    height: 40px;
  }
  p {
    margin: 7px 0px 0px 15px;
    font-family: 'BlissProReg';
    font-size: 20px;
  }
  p span {
    font-size: 30px;
    font-family: 'BlissProExtraLight';
  }
}

.open_menu {
  display: flex;
  align-items: center;
  padding-top: 5px;
  h2 {
    color: $grey;
  }
}
.hamburger {
  width: 26px;
  height: 3px;
  margin: 4px 12px 4px 0px;
  background-color: #00959C;
}
.close_menu {
  img {
    padding-top: 5px;
    width: 38px;
  }
}
.menu3 {
  padding-bottom: 15px;

  .quarter_column > div {
    max-width: 336px;
    margin-right: 15px;
  }
  h1,h2,p,a {
    color: white !important;
  }
  h1 {
    font-size: 30px;
    margin-bottom: 15px;
  }
  > div:first-child p:not(:last-child) {
    margin-bottom: 35px !important;
  }
}

/* ------------------------------ */

/* Footer */

.half_column #footer, .three_quarter_column #footer {
  display: none;
}

#footer {
  position: absolute;
  bottom: 0;
  background-color: #E2EBEE;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;

  ul {
    margin: 0;
    /*align list element*/
    /*height: 33px;*/
    padding-left: $page_content_padding_lr;
    padding-right: $page_content_padding_lr;
    line-height: 33px;
    vertical-align: middle;
    overflow: hidden;
  }
  li {
    padding-right: $page_content_padding_lr;
    padding-bottom: 0px;
    display: inline-block;
  }
  li:last-child {
    float: right;
    padding-right: 0;
  }
  li:last-child:after {
    font-family: 'Zukunft-Icons';
    content: '\2303';
  }
  li img {
    height: 23px;
    vertical-align: middle;
    padding-bottom: 4px;
  }
}

/* ------------------------------- */

/* Page Content Left Side */


.page_content {
  margin: 0px $page_content_padding_lr 25px $page_content_padding_lr ;

  > *:not(img) {
    max-width: 630px;
  }
  > img {
    max-width: 736px;
  }
  h1,h2,h3,h4,h5,h6,h7 {
    margin: 0;
    padding-bottom: 7px;
  }
  /*p {
    /*padding-bottom: 25px;
  }*/
}
.page_title {
  margin-left: $page_content_padding_lr;
  margin-right: $page_content_padding_lr;
  margin-bottom: 15px;
  max-width: 1200px;
}
.page_content_large {
  margin: 0px $page_content_padding_lr 0px $page_content_padding_lr;
  max-width: 736px;

}
.page_content_xl {
  margin: 0px $page_content_padding_lr 0px $page_content_padding_lr;
  max-width: 1200px;
  clear: both;
}
/* ---------------------------------- */


/* For special contents
 * like citations, link lists...
 */

.special_box{
  position: relative;
  overflow: auto;
}

.special_line {
  position: absolute;
  width: 40px;
  height: 100%;

  > img {
    width: 100%;
  }
  > .citation_icon:before {
    font-family: 'Zukunft-Icons';
    font-size: 38px;
    color: $orange;
    content: '\2760';
  }
  > .shortly_said_icon:before {
    font-family: 'Zukunft-Icons';
    font-size: 38px;
    color: $orange;
    content: '\1f5cf';
  }
  > .link_list_icon:before {
    font-family: 'Zukunft-Icons';
    font-size: 38px;
    color:$orange;
    content: '\1f517';
  }
  > div {
    position: absolute;
    top: 45px;
    bottom: 3px;
    left: 16px;
    width: 2.5px;
    background-color: $orange;
  }
}
.special_main {
  margin-left: 40px;
  padding-left: 5px;
  padding-top: 10px;
  overflow: hidden;
  .link_list_content {
    > * {
      text-indent: -20px;
      margin-left: 20px;
    }
  }
}

.img_header {
  img {
    vertical-align: middle;
    margin-bottom: 6px;
    padding-right: 5px;
  }
}
/* ------------------------------ */

/* Shortcut list */

#shortcut_list {
  overflow: visible;
  margin: 0px 0px 0px 26px;
  li {
    margin-bottom: 4px;
    text-indent: -1.5em;
  }
  li:first-child {
    margin-bottom: 15px;
  }
  h7 {
    color: white
  }
  a {
    color: white;
  }
  a:before {
    content: "\2192";
    font-family: 'Zukunft-Icons';
    color: $light_green;
    margin-right: 7px;
  }
}

/* ----------------------------------- */

/* Homepage */

.homepage_container {
  height: 100%;
  position: relative;

  p {
    margin: 0;
    font-size: 17px;
  }
  > div > a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 15px));
    width: 230px;
    height: 50px;
    text-align: center;
    color: black;
  }
  > div > a > div {
    padding: 10px;
    background-color: white;
    transform: scale(1);
    transition: all .2s ease-in-out;
  }
  > div > a > div:hover {
    transform: scale(1.1) perspective(1px);
  }
  > div > a > div > p:first-child {
    font-family: 'BlissProExtraLight';
    font-size: 26px;
    font-weight: normal;
  }
  > div:nth-child(2) {
    background-color: $dark_green;
  }
  > div:nth-child(3) {
    background-color: $orange;
  }
  > div:nth-child(1) > a > div {
    border: solid $blue 1px;
  }
  > div:nth-child(2) > a > div {
    border: solid #5799A3 1px;
  }
  > div:nth-child(3) > a > div {
    border: solid #F4B99B 1px;
  }
  > div:nth-child(4) > a > div {
    border: solid $light_green 1px;
  }

}

/* ------------------------------- */

/* FÃ¼r Partner/ Anmeldung Theme */


.welcome_text {
  margin-bottom: 40px;
  h4 {
    margin: 0;
  }
  p {
    margin: 0;
  }
  a:after {
    font-family: 'Zukunft-Icons';
    content: '\2009\2192';

  }
}

.approval_container {
  background-color: $wait_approval;
  padding: 10px 10px 10px 10px;
}
.approved {
  background-color: $approved !important;
}

/* -------------------------------- */

/* Login Form Container */

input#username, input#password {
  @extend %form_text_input;
  border: solid 1px white;
  background-color: $dark_light_green;
  color: white;
}
input#username::placeholder, input#password::placeholder {
  color: white;
}
/* Change Autocomplete styles in Chrome Safari*/
input#username:-webkit-autofill, input#password:-webkit-autofill,
input#username:-webkit-autofill:hover, input#password:hover,
input#username:-webkit-autofill:focus, input#password:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px $dark_light_green inset;
}
input#registration_email:-webkit-autofill, input#registration_plainPassword_first:-webkit-autofill, input#registration_plainPassword_second:-webkit-autofill,
input#registration_email:hover, input#registration_plainPassword_first:hover, input#registration_plainPassword_second:hover,
input#registration_email:focus, input#registration_plainPassword_first:focus, input#registration_plainPassword_second:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.login_container button[type="submit"] {
  @extend %form_submit_button;
  background-color: white;
  color: $dark_light_green;
  &:hover {
    color: white;
    background-color: $dark_green !important;
  }
}
.login_container a {
  line-height: 40px;
  margin-top: 10px;
  color: white;
}

/* -------------------------------- */

/* Registration Form Container */

#registration {
  button[type="submit"] {
    @extend %form_submit_button;
    margin-top: 20px;
  }
  input {
    @extend %form_text_input;
    max-width: 330px;
  }
  input::placeholder{
    color: black;
  }
  #konditionen {
    margin-top: 20px;
    text-indent: -26px;
    padding-left: 26px;
    > div:not(:first-child) {
      padding-top: 10px;
    }
    label {
      font-family: 'BlissProLightItalic';
      vertical-align: top;
      font-size: 15px;
    }
  }
}

#registrierung {
  p:first-child {
    font-family: 'BlissProLight';
    font-size: 20px;
    color: $teal;
  }
}

.registrated {
  color: white;
  .login_container {
    input {
      width: 100% !important;
    }
  }
}

/* -------------------------------- */

/* Account Data Form Container */


#account_data {
  button[type="submit"] {
    @extend %form_submit_button;
    margin-bottom: 10px;
  }

  label {
    font-family: 'BlissProMedium';
    font-size: 18px;
  }
  input {
    @extend %form_text_input;
    max-width: 330px;
    margin-top: 3px;
    margin-bottom: 18px;
  }
  input[readonly="readonly"] {
    opacity: 0.7;
  }
}


/* -------------------------------- */

/* Dropdown Design */

.dropdown {
  border: 1px solid #00AF9B;
  vertical-align: top;
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0 5px 0 5px;
    background-color: white;
    text-decoration: none;
    list-style: none;
    height: 37px;
    line-height: 37px;
    position: relative;
    display: none;
  }
  &.opened li {
    display: block;
  }
  li.selected_el {
    display: block;
  }
  li.selected_el:after {
    font-family: 'Zukunft-Icons';
    content: "\2304\2009";
    text-align: right;
    float: right;
    color: $light_green;
    width: 18px;
  }
  &.opened li.selected_el:after {
    font-family: 'Zukunft-Icons';
    content: "\2303";
    color: $light_green;
  }

  .list_box {
    max-height: 200px;
    overflow-y: scroll;
    margin-left: -1px;
    margin-right: -1px;
    display: none;
  }
  &.opened .list_box {
    border: 1px solid $light_green;
    position: relative;
    z-index: 100;
    cursor: pointer;
  }
  .list_box li {
    z-index: 100;
  }
}

/* -------------------------------- */


/* Institution reset Passwort */

#reset_password {
  input {
    @extend %form_text_input;
    max-width: 330px;
  }
  button {
    @extend %form_submit_button;
  }
}

/* ----------------------------- */

/* Offer */

%active_offer_container {
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  border-radius: 11px;
  padding: 0px 5px 0px 5px;
  cursor: pointer;
}

.active_offer {
  @extend %active_offer_container;
  background-color: #77CE52;
  color: white;
  font-size: 15px;
  letter-spacing: 0.01em;
}
.active_offer:hover {
  background-color: #A9E68F;
}
.active_offer:before {
  font-family: 'Zukunft-Icons';
  content: '\1F441';
  padding-right: 2px;
}
.inactive_offer {
  @extend %active_offer_container;
  background-color: #7C7C7C;
  color: white;
  font-size: 15px;
  letter-spacing: 0.01em;
}
.inactive_offer:hover {
  background-color: #AFAFAF;
}
.inactive_offer:before {
  font-family: 'Zukunft-Icons';
  content: '\E001';
  padding-right: 2px;
}

.down_active_offer {
  font-size: 18px;
  letter-spacing: 0.01em;
}
/*.down_active_offer:hover {
  background-color: #A9E68F;
}*/
.down_active_offer:before {
  font-family: 'Zukunft-Icons';
  cursor: pointer;
  content: '\E001';
  padding-right: 2px;
}
.down_inactive_offer {
  font-size: 18px;
  letter-spacing: 0.01em;
}
/*.down_inactive_offer:hover {
  background-color: #AFAFAF;
}*/
.down_inactive_offer:before {
  font-family: 'Zukunft-Icons';
  cursor: pointer;
  content: '\1F441';
  padding-right: 2px;
}

.created_date {
  padding: 8px 10px 1px 5px;
  font-family: 'BlissProLight';
  font-size: 15px;
  letter-spacing: 0.03em;
}

ul.offer-list {
  margin-bottom: 0;
  hr {
    background-color: #E2EBEE;
    height: 9px;
    border: none;
    margin: 0 -30px /*minus padded*/
  }
  div {
    overflow: hidden;
  }
  li {
    padding: 0px 0px 10px 0px;
    .offer-info {
      padding: 0px 0px 10px 0px;
      @extend %flexed;
      > div {
        padding-top: 10px;
      }
    }
    .created_date {
      display: inline-block;
    }
  }
  h4 {
    margin: 0px;
    padding: 0px 0px 10px 0px;
  }
  .offer-edit {
    a:not(:first-child) {
      padding-left: 20px;
    }
  }
}

.dateTypeForm {
  > div {
    display: inline-block;
    vertical-align: middle;
    &:nth-child(1) {
      width: 136px;
    }
    &:nth-child(2) {
      width: 55px;
    }
    &:nth-child(3) {
      width: 71px;
    }
    &:nth-child(4) {
      width: 75px;
    }
  }
  .dropdown {
    border: 1px solid $grey;
    li.selected_el:after {
      color: $grey;
    }
    &.opened li.selected_el:after {
      color: $grey;
    }

    &.opened .list_box {
      border: 1px solid $grey;
    }
  }
}
form[name="edit_offer"] {
  clear: both;

  > div {
    margin: 20px 0px 10px 0px;
  }
  > div:last-child {
    margin: 0px;
    text-align: right;
  }
  input[type="text"], input[type="email"]{
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    font-size: 18px;
    font-family: 'BlissProReg';
    padding: 0px 10px 0px 10px;
    border: 1px solid #00AF9B;
  }

  button[type="submit"] {
    @extend %form_submit_button;
  }

  label {
    font-family: 'BlissProMedium';
    font-size: 18px;
  }
  .inline-block.xs_width > div:first-child {
    width: 136px;
  }
  .inline-block.xs_width > div:last-child {
    max-width: 433px;
    width: 100%;
  }
  #arbeitsart > div {
    margin: 15px 0px 0px 26px;
    text-indent: -26px;
  }
  #spezialisierung {
    margin-bottom: 40px;
    > div {
      margin: 15px 0px 0px 0px;
    }
  }
  h4 {
    padding-top: 20px;
  }
  .flex-cell {
    display: flex;
    align-items: center;

    > div:first-child {
      min-width: 130px;
    }
    > div:last-child {
      width: 100%;
    }
  }
}

/* ------------------------------ */

/* CKE Styling */
.cke_bottom {
  display: none !important;
}
.cke_top {
  border-bottom-color:  #00af9b !important;
}
.cke {
  border-top-color: #00af9b !important;
  border-right-color: #00af9b !important;
  border-bottom-color: #00af9b !important;
  border-left-color: #00af9b !important;
}
ul.colored_list {
  list-style-position: inside;
  padding-left: 5px;
  display: table;
}
ul.colored_list li {
  display: table-row;
}
ul.colored_list li:before {
  content: "\2022";
  color: #00af9b;
  font-weight: bold;
  padding-right: 5px;
  display: table-cell;
}
ul.colored_list li > * {
  display: initial;
  display: inline-flex;
}
ol.colored_list {
  counter-reset: li;
  padding-left: 5px;
  display: table;
}
ol.colored_list li {
  counter-increment: li;
  display: table-row;
  list-style-position: outside;
}
ol.colored_list li:before {
  content: counter(li)'.';
  color: #00af9b;
  padding-right: 5px;
  display: table-cell;
}
ol.colored_list li > * {
  display: initial;
  display: inline-flex;
}
.inline_anchor {
  text-decoration: underline;
}
.inline_anchor:hover {
  text-decoration: none;
}
.internal_link:after {
  font-family: 'Zukunft-Icons';
  font-weight: normal;
  content: '\2005\2192';
  text-decoration: none !important;
}
.external_link:before {
  font-family: 'DGPIcons';
  font-weight: normal;
  content: "\2197\2005";
  text-decoration: none !important;
}
/* ------------------------------- */


/* Change / New Offer */
.offer-type-list {
  /*li {
  }*/
  li h7 {
    display: inline-block;
  }
  /*li a h7 {
  }*/
  li a p {
    display: inline-block;
  }
  li a p:last-child:after {
    font-family: 'Zukunft-Icons';
    content: '\2192';
  }
}
/* ------------------------------- */

/* Specific Offer */

.offer_bar {
  margin: 0px 0px 0px 0px;
  @extend %flexed;
  > div {
    padding: 10px 0px 0px 0px;
  }
  .created_date {
    display: inline-block;
  }
}

.institution_info_benefits .three_column > * {
  display: inline-block;
  vertical-align: top;
  width: 32%;
}
.institution_info_benefits, .institution_info_work_culture {
  margin: 0px 0px 30px 0px;
}
.institution_info, .offer_info {
  p {
    margin-bottom: 9px;
  }
  h4 {
    padding-top: 0px;
    margin: 20px 0px 16px 0px;
  }
  span {
    font-family: 'BlissProMedium';
  }
  .offer_title {
    font-size: 26px;
  }
  /*.offer_description {
    //padding-bottom: 20px;
  }*/
  .offer_department, .offer_contact, .offer_link {
    > * {
      display: inline-block;
    }
    h4,p {
      padding-right: 5px;
      margin-bottom: 0px;
    }
  }
}

.offer_info {
  padding-bottom: 20px;
}

/* --------------------------------- */

/* Edit Institution Rahmenbedingung */


#institution_rahmenbedingung {
  input[type="text"] {
    @extend %form_text_input;
    width: 100%;
    margin-top: 5px;
  }
  input[type="radio"]+label {
    font-family: 'BlissProLight';
  }
  textarea {
    @extend %form_text_input;
    width: 100%;
    height: 200px;
    margin-top: 0;
    padding: 10px;
    margin-top: 5px;
  }
  label {
    font-family: 'BlissProMedium';
    font-size: 18px;
  }
  h4 {
    padding: 0px 0px 16px 0px;
  }
  p {
    padding: 0px 0px 14px 0px;
  }
  span {
    font-family: 'BlissProMedium';
  }
  .pad {
    padding-bottom: 15px;
  }
  .inline-block > div {
    vertical-align: top;
    width: 47%;
  }
  .inline-block > div:first-child {
    margin-right: 4.6%;
  }
  button[type="submit"] {
    @extend %form_submit_button;
  }
  #institution_type label {
    margin-top: 15px;
  }
  #institution_bedNumber {
    padding-left: 40px;
    label:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  .inline-block > div > div {
    padding-bottom: 15px;
  }
  #institution_type_5 + label{
    display: table-cell;
    width: 120px;
  }
  #institution_sonstiges {
    display: table-cell;
  }
  #institution_type {
    padding-bottom: 20px;
  }
  #organisatorisches, #institutionsgroesse, #familie_und_karriere {
    padding: 0px 0px 47px 0px;

    p:last-child {
      padding: 0px 0px 0px 0px;
    }
  }

  .table-cell {
    width: 100%;
    display: table;
    > div {
      display: table-cell;
      width: 50%;
      padding-top: 15px;
      &:first-child {
        border-right: 10px solid transparent;
      }
    }
  }

  #institution_equalityCommission_ja, #institution_equalityCommission_nein {
    margin-top: 10px;
  }
  #institution_parentalLeave {
    padding-top: 20px;
  }
  #cke_institution_description {
    margin-top: 5px;
  }

}

.institution_partition {
  text-align: right;
  margin-top: 15px;
  > * {
    display: inline-block;
  }
  h1 {
    color: #00AF9B;
    vertical-align: middle;
  }
  input[type="submit"] {
    @extend %form_submit_button;
  }
  button[type="submit"] {
    @extend  %form_submit_button;
  }
  input, button, .button_box {
    margin-left: 50px;
  }
}
#mobile_pad_indication {
  display: none;
  padding: 10px 0px 0px 0px;
  h3 {
    line-height: 30px;
    font-size: 17px;
    text-align: center;
    color: $dark_green;
  }
  h3:after {
    content: url('../logos/quer.svg');
    vertical-align: middle;
  }
}
#institution_benefit {
  h7 {
    color: white;
    padding-left: 6px;
    padding-right: 6px;
    display: block;
    line-height: 25px;
  }
}

/* ---------------------------------- */

/* edit offer bar */

.edit_offer_bar {
  @extend %flexed;
  > div {
    padding-top: 10px;
  }
  h7[class$="-background-color"] {
    display: inline-block;
  }
}
#cke_edit_offer_description {
  margin-top: 5px;
}

/* --------------------------------- */

.small_info {
  a {
    display: none;
  }
}

.mobile_header {
  display: none;

  > div {
    color: white;
  }
  h2 {
    padding-bottom: 15px;
  }
  h7 {
    padding-bottom: 10px;
    display: inline-block;
  }
}

.form-expanded-dropdown {
   label {
     display: block;
     padding-bottom: 15px;
   }
 }

.department_list {
  padding-bottom: 10px;
}
#meine_angebote {
  flex: 100;
  overflow: visible;
}
