%all_rows_checkbox {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
}


table#institution_benefit {
  width: 100%;
  padding-top: 10px;
  tr > td:first-child {
    width: 231px;
    position: absolute;
    padding-left: 10px;
    padding-right: 12px;
    left: 20px;
    height: 48px;
    border-bottom: 2px solid white;
    display: table;
    div {
      display: table-cell;
      vertical-align: middle;
      padding-right: 20px;
    }
  }
  tr > td:first-child span {
    @extend %all_rows_checkbox;
  }
  tr > td:last-child {
    width: 50px;
  }
  .checkbox-radio-span {
    margin: 0px;
  }
  tr > td:not(:first-child) {
    text-align: center; /* center checkbox horizontally */
    vertical-align: middle; /* center checkbox vertically */
    width: 100px;
  }
  tr > th:first-child {
    position: absolute;
    text-align: left;
    font-family: 'BlissProMedium';
    font-weight: normal;
    font-size: 21px;
    padding-left: 10px;
    left: 20px;
    width: 255px;
  }
  tr > th:first-child span {
    @extend %all_rows_checkbox;
    font-size: 15px;
    margin-right: 16px;
  }
  tr > th:not(:first-child) > div {
    margin: 0px !important;
    padding: 0px !important;
    min-width: 100px;
    height: 50px;
  }
  tr:not(:first-child) {
    height: 50px;
  }

  tr:nth-child(2n) td {
    background-color: rgba(5, 56, 117, 0.03);
  }
  tr:nth-child(2n+1) td:nth-child(2) {
    background-color: #F1F4F7;
  }
  tr:nth-child(2n) td:nth-child(2) {
    background-color: #E9EDF3;
  }
  tr:nth-child(2n+1) td:nth-child(3) {
    background-color: #F4F6F9;
  }
  tr:nth-child(2n) td:nth-child(3) {
    background-color: #EDF0F5;
  }
  tr:nth-child(2n+1) td:nth-child(4) {
    background-color: #F8F9FB;
  }
  tr:nth-child(2n) td:nth-child(4) {
    background-color: #F0F2F7;
  }
  tr:nth-child(2n+1) td:nth-child(5) {
    background-color: #FEF2F4;
  }
  tr:nth-child(2n) td:nth-child(5) {
    background-color: #F6EBF0;
  }
  tr:nth-child(2n+1) td:nth-child(6) {
    background-color: #F7F7F7;
  }
  tr:nth-child(2n) td:nth-child(6) {
    background-color: #F0F1F4;
  }
  tr:nth-child(2n+1) td:nth-child(7) {
    background-color: #F4F4F4;
  }
  tr:nth-child(2n) td:nth-child(7) {
    background-color: #ECEDF0;
  }
  tr:nth-child(2n+1) td:nth-child(8) {
    background-color: #FBFBFB;
  }
  tr:nth-child(2n) td:nth-child(8) {
    background-color: #F4F4F7;
  }
  tr:nth-child(2n+1) td:nth-child(9) {
    background-color: #F1FAF9;
  }
  tr:nth-child(2n) td:nth-child(9) {
    background-color: #E9F4F5;
  }
  tr:nth-child(2n+1) td:nth-child(10) {
    background-color: #F6FBFB;
  }
  tr:nth-child(2n) td:nth-child(10) {
    background-color: #EEF4F7;
  }
  tr:nth-child(2n+1) td:nth-child(11) {
    background-color: #F3F6F7;
  }
  tr:nth-child(2n) td:nth-child(11) {
    background-color: #ECF0F3;
  }
  td:nth-child(6) {
    min-width: 90px;
  }
  tr > td:first-child span input + label span {
    margin-right: 10px;
  }
}
table#institution_rahmenbedingung tr td  label span {
  margin-right: 0px;
}