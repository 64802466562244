@import './_fonts';
@import './_colors';
@import './_tags';

$pad: 10px;

.cke_editable {
  padding:$pad;

  p{
    font-family: 'BlissProLight' !important;
  }

  ul,ol {
    padding-left: $pad;
  }

  ul li:before {
    content: "\2022";
    color: #00af9b;
    font-weight: bold;
    margin-right: 5px;
  }
  ol {
    list-style: none;
    counter-reset: li;
  }
  ol li {
    counter-increment: li
  }
  ol  li:before {
    content: counter(li)'.';
    color: #00af9b;
    margin-right: 5px;
  }
}
.cke_notifications_area {
  display: none !important;
}