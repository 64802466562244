@charset "UTF-8";

@font-face {
  font-family: "DGPIcons";
  src: url("../fonts/DGPIcons-Regular.woff") format("woff");
}

@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-rg.woff") format("woff");
}

@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-bd.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-md.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-xlt.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-lt.woff") format("woff");
  font-weight: 200;
}

@font-face {
  font-family: "BlissPro";
  src: url("../fonts/blsw-ltit.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "BlissProReg";
  src: url("../fonts/blsw-rg.woff") format("woff");
}

@font-face {
  font-family: "BlissProBold";
  src: url("../fonts/blsw-bd.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "BlissProMedium";
  src: url("../fonts/blsw-md.woff") format("woff");
}

@font-face {
  font-family: "BlissProExtraLight";
  src: url("../fonts/blsw-xlt.woff") format("woff");
}

@font-face {
  font-family: "BlissProLight";
  src: url("../fonts/blsw-lt.woff") format("woff");
}

@font-face {
  font-family: "BlissProLightItalic";
  src: url("../fonts/blsw-ltit.woff") format("woff");
}

@font-face {
  font-family: "Zukunft-Icons";
  src: url("../fonts/DGPZukunft-Regular.woff") format("woff");
}

.pflegepraktikum-background-color,
.famulatur-background-color,
.praktisches-jahr-background-color,
.assistent\/assistentin-in-weiterbildung-background-color,
.oberarzt\/ärztin-background-color,
.chefarzt\/ärztin-background-color,
.facharzt\/ärztin-background-color,
.wissenschaftliche-mitarbeitende-background-color,
.atmungstherapeuten-background-color,
.sonstige-background-color,
.landesärztekammer-background-color,
.weiterbildungsbefugte-background-color {
  color: white;
  padding: 2px 5px 0px 5px !important;
  background-color: black;
  margin: 5px 5px 5px 0px !important;
  height: 22px;
  white-space: nowrap;
}

.pflegepraktikum-background-color {
  background-color: #00AF9B;
}

.famulatur-background-color {
  background-color: #64BEC3;
}

.praktisches-jahr-background-color {
  background-color: #336A73;
}

.assistent\/assistentin-in-weiterbildung-background-color {
  background-color: #ED193A;
}

.oberarzt\/ärztin-background-color {
  background-color: #436A97;
}

.chefarzt\/ärztin-background-color {
  background-color: #053875;
}

.facharzt\/ärztin-background-color {
  background-color: #819BB9;
}

.wissenschaftliche-mitarbeitende-background-color {
  background-color: #7C7C7C;
}

.atmungstherapeuten-background-color {
  background-color: #3E3E3E;
}

.sonstige-background-color {
  background-color: #C1C1C1;
  color: black !important;
}

.landesärztekammer-background-color {
  color: black !important;
  background-color: white;
  border: 1px solid black;
}

.weiterbildungsbefugte-background-color {
  background-color: #F26821;
}

.pflegepraktikum-color {
  color: #00AF9B;
}

.famulatur-color {
  color: #64BEC3;
}

.praktisches-jahr-color {
  color: #336A73;
}

.assistent\/assistentin-in-weiterbildung-color {
  color: #ED193A;
}

.oberarzt\/ärztin-color {
  color: #436A97;
}

.chefarzt\/ärztin-color {
  color: #053875;
}

.facharzt\/ärztin-color {
  color: #819BB9;
}

.wissenschaftliche-mitarbeitende-color {
  color: #7C7C7C;
}

.atmungstherapeuten-color {
  color: #3E3E3E;
}

.sonstige-color {
  color: #C1C1C1;
}

.weiterbildungsbefugte-color {
  color: #F26821;
}

.orange {
  color: #d47143 !important;
}

.teal {
  color: #05454f !important;
}

.blue {
  color: #053875 !important;
}

.light_green {
  color: #00af9b !important;
}

.grey {
  color: #3e3e3e !important;
}

html,
body {
  font-family: 'BlissProLight';
  font-size: 18px;
  color: black;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  margin: 0;
  padding: 0;
  line-height: 1.4em;
}

h1 {
  font-family: 'BlissProLightItalic';
  font-weight: normal;
  font-size: 33px;
  color: #004550;
  letter-spacing: .01em;
}

h2 {
  font-family: 'BlissProExtraLight';
  font-weight: normal;
  font-size: 26px;
  color: #3e3e3e;
  text-transform: uppercase;
  letter-spacing: .01em;
}

h3 {
  font-family: 'BlissProLightItalic';
  font-size: 24px;
  color: #3e3e3e;
  font-weight: normal;
}

h4 {
  font-family: 'BlissProMedium';
  color: #3e3e3e;
  font-size: 23px;
  padding-top: 10px;
  font-weight: normal;
}

h5 {
  font-family: 'BlissProLight';
  font-size: 20px;
  color: #004550;
  font-weight: normal;
}

h6 {
  font-family: 'BlissProLight';
  font-size: 20px;
  color: #006B7F;
  font-weight: normal;
}

h7 {
  font-family: 'BlissProMedium';
  color: #3e3e3e;
  font-size: 15px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}

a {
  text-decoration: none;
  color: #00af9b;
}

p {
  font-size: 18px;
  margin-top: 0;
  line-height: 1.4em;
  color: #3e3e3e;
}

input,
button {
  font-family: 'BlissProReg';
  color: #3e3e3e;
  border-radius: 0;
}

strong {
  font-family: BlissProBold;
  font-weight: normal;
}

em {
  font-family: 'BlissProLightItalic';
  font-style: normal;
  line-height: 1.4em;
}

ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 0px;
  line-height: 1.4em;
}

li {
  padding-bottom: 5px;
  color: #3e3e3e;
}

ol {
  list-style: none;
  padding-left: 0px;
  line-height: 1.4em;
}

b {
  font-family: BlissPro;
  font-weight: unset;
}

textarea {
  color: #3e3e3e;
  border-radius: 0;
}

span {
  color: #3e3e3e;
}

label {
  color: #3e3e3e;
}

input[type="text"],
.dropdown {
  font-size: 20px;
  height: 40px;
  width: 100%;
  border: solid 1px #00af9b;
  box-sizing: border-box;
  margin-top: 5px;
}

input[type="radio"],
input[type="checkbox"] {
  -webkit-appearance: checkbox;
  /* Chrome, Safari, Opera */
  -moz-appearance: checkbox;
  /* Firefox */
  -ms-appearance: checkbox;
  /* not currently supported */
  margin: 0 10px 10px 0;
}

/* Checkbox and Radio Buttons */

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label span {
  background: url("../logos/radiobutton-on.svg") #fff;
  background-repeat: no-repeat;
  background-position: center;
  height: 15px;
  width: 15px;
  display: inline-block;
  padding: 0;
  margin-right: 10px;
}

input[type="radio"] + label span {
  background: url("../logos/radiobutton-off.svg") #fff;
  height: 15px;
  width: 15px;
  display: inline-block;
  padding: 0;
  margin-bottom: 0;
  margin-right: 10px;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + label span {
  background: url("../logos/checkbox-checked.svg") #fff;
  background-repeat: no-repeat;
  background-position: center;
  height: 14px;
  width: 14px;
  display: inline-block;
  padding: 0;
  margin-right: 10px;
}

input[type="checkbox"] + label span {
  background: url("../logos/checkbox-unchecked.svg") #fff;
  height: 14px;
  width: 14px;
  display: inline-block;
  padding: 0;
  margin-bottom: 0;
  margin-right: 10px;
}

textarea {
  margin-top: 5px;
}

/* ------------------------------------ */

#sliders-checkout-root .rc-slider {
  margin-top: 15px;
}

#sliders-checkout-root .slider-men {
  margin-top: 20px !important;
}

.toggle-wrapper {
  overflow: auto;
}

.toggle-wrapper h4 {
  cursor: pointer;
}

.toggle-wrapper h4:after {
  margin-left: 5px;
}

.toggle-wrapper.toggle-closed .toggle-content {
  display: none;
}

.toggle-wrapper.toggle-open h4:after {
  font-family: Zukunft-Icons;
  content: "\2303";
}

.toggle-wrapper.toggle-closed h4:after {
  font-family: Zukunft-Icons;
  content: "\2304";
}

.toggle-wrapper .toggle-content {
  overflow: auto;
}

.pflegepraktikum-background-color,
.famulatur-background-color,
.praktisches-jahr-background-color,
.assistent\/assistentin-in-weiterbildung-background-color,
.oberarzt\/ärztin-background-color,
.chefarzt\/ärztin-background-color,
.facharzt\/ärztin-background-color,
.wissenschaftliche-mitarbeitende-background-color,
.atmungstherapeuten-background-color,
.sonstige-background-color,
.landesärztekammer-background-color,
.weiterbildungsbefugte-background-color {
  color: white;
  padding: 2px 5px 0px 5px !important;
  background-color: black;
  margin: 5px 5px 5px 0px !important;
  height: 22px;
  white-space: nowrap;
}

.pflegepraktikum-background-color {
  background-color: #00AF9B;
}

.famulatur-background-color {
  background-color: #64BEC3;
}

.praktisches-jahr-background-color {
  background-color: #336A73;
}

.assistent\/assistentin-in-weiterbildung-background-color {
  background-color: #ED193A;
}

.oberarzt\/ärztin-background-color {
  background-color: #436A97;
}

.chefarzt\/ärztin-background-color {
  background-color: #053875;
}

.facharzt\/ärztin-background-color {
  background-color: #819BB9;
}

.wissenschaftliche-mitarbeitende-background-color {
  background-color: #7C7C7C;
}

.atmungstherapeuten-background-color {
  background-color: #3E3E3E;
}

.sonstige-background-color {
  background-color: #C1C1C1;
  color: black !important;
}

.landesärztekammer-background-color {
  color: black !important;
  background-color: white;
  border: 1px solid black;
}

.weiterbildungsbefugte-background-color {
  background-color: #F26821;
}

.pflegepraktikum-color {
  color: #00AF9B;
}

.famulatur-color {
  color: #64BEC3;
}

.praktisches-jahr-color {
  color: #336A73;
}

.assistent\/assistentin-in-weiterbildung-color {
  color: #ED193A;
}

.oberarzt\/ärztin-color {
  color: #436A97;
}

.chefarzt\/ärztin-color {
  color: #053875;
}

.facharzt\/ärztin-color {
  color: #819BB9;
}

.wissenschaftliche-mitarbeitende-color {
  color: #7C7C7C;
}

.atmungstherapeuten-color {
  color: #3E3E3E;
}

.sonstige-color {
  color: #C1C1C1;
}

.weiterbildungsbefugte-color {
  color: #F26821;
}

.orange {
  color: #d47143 !important;
}

.teal {
  color: #05454f !important;
}

.blue {
  color: #053875 !important;
}

.light_green {
  color: #00af9b !important;
}

.grey {
  color: #3e3e3e !important;
}

.invalid_input {
  border: 1px solid #ED193A !important;
}

label.invalid_input {
  border: 1px solid transparent !important;
  color: #ED193A;
}

#registration .invalid_input + input {
  border: 1px solid #ED193A !important;
}

#registration_plainPassword_second {
  margin-bottom: 10px;
}

.form_error {
  margin: 2px 0px 2px 0px;
  color: #ED193A;
}

.form_error li {
  margin: 0px;
  padding: 0px;
}

.form_error li:before {
  background-image: url("../../public/_logos/exclamation.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  padding-right: 20px;
  margin-right: 5px;
  content: "";
}

.login_error {
  max-width: 464px;
  margin: 0px;
  color: white;
}

.login_error li {
  margin: 0px;
  padding: 0px;
}

.login_error li:before {
  background-image: url("../../public/_logos/exclamation_white.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  padding-right: 20px;
  margin-right: 5px;
  content: "";
  fill: white;
}

.invalid_login_input {
  border: 1px solid #FA98A8 !important;
}

.dropdown.invalid_dropdown {
  border: 1px solid #ED193A !important;
}

.dropdown.invalid_dropdown li.selected_el:after {
  color: #ED193A !important;
}

.dropdown.invalid_dropdown.opened li.selected_el:after {
  color: #ED193A !important;
}

.dropdown.invalid_dropdown.opened .list_box {
  border: 1px solid #ED193A !important;
}

.slider-men .rc-slider > div,
.slider-women .rc-slider > div {
  margin-left: 5px;
}

/* Variable */

.half_column {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
}

#content.anmeldung {
  padding-bottom: 90px;
}

#content:not(.anmeldung) .half_column:nth-child(1) {
  padding-top: 50px;
  box-sizing: border-box;
  overflow: scroll;
  display: inline-block;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
}

#content:not(.anmeldung) .half_column:nth-child(2) .half_column:nth-child(1) {
  overflow: hidden;
  padding-top: 50px;
}

#content:not(.anmeldung) .half_column:nth-child(2) .half_column:nth-child(2) {
  padding-top: 50px;
  box-sizing: border-box;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

#content:not(.anmeldung) .three_quarter_column {
  padding-top: 50px;
  box-sizing: border-box;
}

#content:not(.anmeldung) .quarter_column {
  padding-top: 50px;
  box-sizing: border-box;
}

#content:not(.anmeldung) .quarter_column:after,
#content:not(.anmeldung) .three_quarter_column:after,
#content:not(.anmeldung) .half_column:after {
  content: '';
  display: block;
  padding-bottom: 90px;
}

.third_column {
  position: relative;
  float: left;
  width: 33%;
  min-height: 100%;
}

.quarter_column {
  position: relative;
  float: left;
  width: 25%;
  height: 100%;
}

.three_quarter_column {
  position: relative;
  float: left;
  width: 75%;
  height: 100%;
}

#content {
  height: calc(100% - 66px);
}

#p17 .quarter_column {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

#p17 .three_quarter_column {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  height: 100%;
}

.wrapper {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.wrap {
  overflow: auto;
}

.padded {
  padding: 0px 20px 0px 20px;
  overflow: auto;
}

.pad {
  padding-bottom: 15px;
}

.filled {
  padding: 0px 30px 0px 30px;
  overflow: auto;
}

.position_relative {
  position: relative;
  height: 100%;
}

.stick_bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.left {
  float: left;
}

.right {
  float: right;
}

.inline-block > * {
  display: inline-block;
}

.block > *:not(script) {
  display: block;
}

.clear {
  clear: both;
}

.underline {
  text-decoration: underline;
}

.underline:hover {
  text-decoration: none;
}

.xs_width {
  max-width: 574px;
}

.s_width {
  max-width: 630px;
}

.l_width {
  max-width: 736px;
}

.right_col_top_box {
  padding-bottom: 50px;
}

.right_col_top_box > h7 {
  padding-bottom: 15px;
  display: block;
}

.right_col_top_box a:hover h3 {
  color: #004550;
}

.anmeldung {
  padding-top: 50px;
}

.anmeldung .quarter_column {
  padding-top: 35px;
}

.anmeldung .quarter_column:nth-child(5) {
  padding-top: 0px !important;
}

.edit-icon:before,
.duplicate-icon:before,
.delete-icon:before,
.public-icon:before {
  font-family: 'Zukunft-Icons';
  padding-right: 3px;
  display: inline-block;
}

ul.offer-list li .offer-info,
.offer_bar,
.edit_offer_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.flex-container-center {
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-icon:before {
  content: '\1F589';
}

.duplicate-icon:before {
  content: url("../logos/duplizieren.svg");
}

.delete-icon:before {
  content: url("../logos/loeschen.svg");
}

.delete-icon a {
  font-size: 18px;
}

.public-icon:before {
  content: '\1F517';
}

.flash-success,
.flash-warning {
  cursor: pointer;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.flash-success {
  background-color: #00af9b;
}

.flash-warning {
  background-color: #FA98A8;
}

.flash-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flash-message h1 {
  display: inline-block;
  vertical-align: text-bottom;
  color: white;
}

.flash-message a {
  margin-left: 10px;
  padding-top: 20px;
  display: inline-block;
}

.right_arrow:after {
  font-family: 'Zukunft-Icons';
  content: '\2009\2192';
}

.dark-green-hover:hover {
  color: #004550 !important;
}

.registration-button {
  display: inline-block;
  padding: 10px 15px 10px 15px;
  margin-top: 10px;
  background-color: white;
}

.registration-button span {
  color: #00959C;
}

.registration-button span:hover {
  color: white;
}

.registration-button:hover {
  background-color: #004550;
}

.registration-button:hover span {
  color: white;
}

table#institution_benefit tr > td:first-child span,
table#institution_benefit tr > th:first-child span {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
}

table#institution_benefit {
  width: 100%;
  padding-top: 10px;
}

table#institution_benefit tr > td:first-child {
  width: 231px;
  position: absolute;
  padding-left: 10px;
  padding-right: 12px;
  left: 20px;
  height: 48px;
  border-bottom: 2px solid white;
  display: table;
}

table#institution_benefit tr > td:first-child div {
  display: table-cell;
  vertical-align: middle;
  padding-right: 20px;
}

table#institution_benefit tr > td:last-child {
  width: 50px;
}

table#institution_benefit .checkbox-radio-span {
  margin: 0px;
}

table#institution_benefit tr > td:not(:first-child) {
  text-align: center;
  /* center checkbox horizontally */
  vertical-align: middle;
  /* center checkbox vertically */
  width: 100px;
}

table#institution_benefit tr > th:first-child {
  position: absolute;
  text-align: left;
  font-family: 'BlissProMedium';
  font-weight: normal;
  font-size: 21px;
  padding-left: 10px;
  left: 20px;
  width: 255px;
}

table#institution_benefit tr > th:first-child span {
  font-size: 15px;
  margin-right: 16px;
}

table#institution_benefit tr > th:not(:first-child) > div {
  margin: 0px !important;
  padding: 0px !important;
  min-width: 100px;
  height: 50px;
}

table#institution_benefit tr:not(:first-child) {
  height: 50px;
}

table#institution_benefit tr:nth-child(2n) td {
  background-color: rgba(5, 56, 117, 0.03);
}

table#institution_benefit tr:nth-child(2n+1) td:nth-child(2) {
  background-color: #F1F4F7;
}

table#institution_benefit tr:nth-child(2n) td:nth-child(2) {
  background-color: #E9EDF3;
}

table#institution_benefit tr:nth-child(2n+1) td:nth-child(3) {
  background-color: #F4F6F9;
}

table#institution_benefit tr:nth-child(2n) td:nth-child(3) {
  background-color: #EDF0F5;
}

table#institution_benefit tr:nth-child(2n+1) td:nth-child(4) {
  background-color: #F8F9FB;
}

table#institution_benefit tr:nth-child(2n) td:nth-child(4) {
  background-color: #F0F2F7;
}

table#institution_benefit tr:nth-child(2n+1) td:nth-child(5) {
  background-color: #FEF2F4;
}

table#institution_benefit tr:nth-child(2n) td:nth-child(5) {
  background-color: #F6EBF0;
}

table#institution_benefit tr:nth-child(2n+1) td:nth-child(6) {
  background-color: #F7F7F7;
}

table#institution_benefit tr:nth-child(2n) td:nth-child(6) {
  background-color: #F0F1F4;
}

table#institution_benefit tr:nth-child(2n+1) td:nth-child(7) {
  background-color: #F4F4F4;
}

table#institution_benefit tr:nth-child(2n) td:nth-child(7) {
  background-color: #ECEDF0;
}

table#institution_benefit tr:nth-child(2n+1) td:nth-child(8) {
  background-color: #FBFBFB;
}

table#institution_benefit tr:nth-child(2n) td:nth-child(8) {
  background-color: #F4F4F7;
}

table#institution_benefit tr:nth-child(2n+1) td:nth-child(9) {
  background-color: #F1FAF9;
}

table#institution_benefit tr:nth-child(2n) td:nth-child(9) {
  background-color: #E9F4F5;
}

table#institution_benefit tr:nth-child(2n+1) td:nth-child(10) {
  background-color: #F6FBFB;
}

table#institution_benefit tr:nth-child(2n) td:nth-child(10) {
  background-color: #EEF4F7;
}

table#institution_benefit tr:nth-child(2n+1) td:nth-child(11) {
  background-color: #F3F6F7;
}

table#institution_benefit tr:nth-child(2n) td:nth-child(11) {
  background-color: #ECF0F3;
}

table#institution_benefit td:nth-child(6) {
  min-width: 90px;
}

table#institution_benefit tr > td:first-child span input + label span {
  margin-right: 10px;
}

table#institution_rahmenbedingung tr td label span {
  margin-right: 0px;
}

@media (max-width: 1024px) {
  .homepage_container {
    min-height: 100%;
  }

  .homepage_container .quarter_column {
    width: 50%;
    min-height: 50%;
    height: 100px;
  }

  .menu_container > .half_column .half_column {
    width: 100%;
    float: none;
    margin-bottom: 30px;
  }

  .menu_container .half_column {
    height: auto;
  }

  div:not(.menu) > .menu_bar {
    background-color: white;
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    height: 40px;
    width: 110px;
    padding: 10px;
    border-bottom: unset !important;
  }

  div:not(.menu) > .menu_bar .menu_title {
    display: none;
  }

  #content {
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }

  #content.filled .quarter_column,
  #content.filled .three_quarter_column {
    width: 100%;
    height: auto;
    padding-bottom: unset;
  }

  #content.filled .quarter_column .small_info,
  #content.filled .three_quarter_column .small_info {
    max-width: unset;
  }

  #content.infocenter > div:last-child,
  #content.unterstuetzung_der_dgp > div:last-child,
  #content.fuer_partner > div:last-child,
  #content.nachwuchs > div:last-child {
    display: none;
  }

  #content.infocenter > .half_column,
  #content.unterstuetzung_der_dgp > .half_column,
  #content.fuer_partner > .half_column,
  #content.nachwuchs > .half_column {
    width: 100%;
  }

  #content .half_column:nth-child(1) {
    padding-top: 0 !important;
  }

  #content .mobile_header {
    display: block;
    width: 100%;
    margin-bottom: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
    box-sizing: border-box;
  }

  #content .mobile_header h2:first-child {
    padding-right: 80px;
  }

  #content #mobile_pad_indication {
    display: block;
  }

  .fuer_partner > .three_quarter_column {
    width: 100%;
    padding-top: 0px !important;
  }

  .fuer_partner > .three_quarter_column .page_content_xl form {
    overflow-x: scroll;
  }

  .fuer_partner > .three_quarter_column .page_content_xl table {
    display: block;
    overflow-x: scroll;
    padding-top: 0px;
  }

  .fuer_partner > .quarter_column {
    display: none;
  }

  #content.anmeldung.filled .quarter_column {
    width: 50%;
  }

  #content.anmeldung.filled .quarter_column.clear {
    clear: unset;
  }

  .quarter_column:after {
    padding-bottom: 0px !important;
  }
}

@media (max-width: 768px) {
  .homepage_container {
    min-height: 100%;
  }

  .homepage_container .quarter_column {
    width: 100%;
    min-height: 25%;
    height: 100px;
  }

  #content:not(.anmeldung) .half_column:nth-child(1) {
    display: flex;
    flex-direction: column;
  }

  #content:not(.anmeldung) .page_title {
    flex: 1;
  }

  #content:not(.anmeldung) .page_content,
  #content:not(.anmeldung) .page_content_large,
  #content:not(.anmeldung) .page_content_xl {
    flex: 10;
  }

  .menu_container > .half_column {
    width: 100%;
  }

  .pneumo_span {
    display: block;
  }

  div:not(.menu) > .menu_bar {
    background-color: white;
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    padding: 10px;
    border-bottom: unset !important;
  }

  div:not(.menu) > .menu_bar .menu_title {
    display: none;
  }

  div:not(.menu) > .menu_bar .open_menu h2 {
    display: none;
  }

  .menu .menu_bar {
    padding-left: 15px;
    padding-right: 15px;
  }

  .menu .menu_title img {
    height: 49px !important;
  }

  .menu .menu_container {
    position: initial !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 30px !important;
  }

  .menu .menu_container h2 {
    margin-bottom: 5px !important;
  }

  .menu #footer {
    position: initial;
    bottom: 0px;
  }

  .hamburger {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  #content {
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }

  body > #footer {
    display: none;
  }

  #footer li {
    padding-right: 15px !important;
  }

  .half_column #footer,
  .three_quarter_column #footer {
    display: block !important;
    position: initial;
    margin-top: 50px;
  }

  .half_column:after,
  .three_quarter_column:after {
    padding-bottom: 0px !important;
  }

  #institution_rahmenbedingung .table-cell > div {
    display: inline-block !important;
    border-right: none !important;
    width: 100% !important;
  }

  .institution_info_benefits .three_column > * {
    width: 49% !important;
  }

  #institution_rahmenbedingung .inline-block > div {
    width: 90% !important;
  }

  #institution_rahmenbedingung .inline-block > div:first-child {
    margin-right: 0 !important;
  }

  #institution_rahmenbedingung #institution_bedNumber {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px !important;
  }

  #institution_rahmenbedingung #institution_equalityCommission_ja,
  #institution_rahmenbedingung #institution_equalityCommission_nein {
    padding-top: 5px;
  }

  #content.anmeldung {
    padding-top: 20px;
  }

  #content.anmeldung.filled .quarter_column {
    width: 100%;
  }

  #content.anmeldung.filled > div > div:first-child {
    padding-bottom: 50px;
  }

  #content.anmeldung.filled .quarter_column.clear {
    clear: unset;
  }

  #content:not(.anmeldung) .quarter_column:first-child {
    padding-top: 20px !important;
  }

  .anmeldung {
    padding-top: 50px;
  }

  .anmeldung .quarter_column {
    padding-top: 0px !important;
  }

  #angebote_der_dgp_toggle {
    display: block;
  }

  #angebote_der_dgp_toggle_text {
    display: none;
  }

  .page_content {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .page_title {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .page_content_large {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .page_content_xl {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .mobile_header {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .filled {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  #footer ul {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  ul.offer-list hr {
    margin: 0 -15px !important;
  }

  #content.anmeldung {
    padding-bottom: 20px !important;
  }

  #content:not(.anmeldung) .half_column:nth-child(1) {
    padding-bottom: 0 !important;
  }

  .three_quarter_column {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 415px) {
  .menu .menu_title p {
    font-size: 19px;
  }

  .menu .menu_title span {
    font-size: 23px;
  }

  .menu .close_menu img {
    width: 30px;
  }

  #content.anmeldung.filled .quarter_column p {
    display: none;
  }
}

#formlocked > input {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.city_autocomplete {
  font-size: 16px;
}

.xdsoft_autocomplete {
  position: relative;
  width: auto !important;
}

.xdsoft_autocomplete input {
  padding-left: 10px;
}

.xdsoft_autocomplete_hint {
  display: none !important;
}

.xdsoft_autocomplete_dropdown {
  max-height: 200px !important;
  overflow: scroll;
  border: 1px solid #00af9b;
  border-top: none;
  box-sizing: border-box;
  background-color: white;
  position: absolute;
  top: 40px;
  left: 0px;
  bottom: auto;
  z-index: 100;
  display: none;
}

.xdsoft_autocomplete_dropdown > div {
  font-size: 20px;
  line-height: 40px;
}

.invalid_input + .xdsoft_autocomplete_dropdown {
  border: 1px solid #ED193A;
  border-top: none;
}

.not_allowed {
  border-color: #ED193A;
}

/* Form Variable */

.login_container button[type="submit"],
#registration button[type="submit"],
#account_data button[type="submit"],
#reset_password button,
form[name="edit_offer"] button[type="submit"],
#institution_rahmenbedingung button[type="submit"],
.institution_partition input[type="submit"],
.institution_partition button[type="submit"] {
  font-family: 'BlissProLight';
  font-weight: normal;
  font-size: 19px;
  height: 40px;
  width: 168px;
  background-color: #00af9b;
  color: white;
  margin-top: 10px;
  border: none;
  cursor: pointer;
}

input#username,
input#password,
#registration input,
#account_data input,
#reset_password input,
#institution_rahmenbedingung input[type="text"],
#institution_rahmenbedingung textarea {
  font-size: 20px;
  height: 40px;
  width: 100%;
  border: solid 1px #00af9b;
  box-sizing: border-box;
  background-color: white;
  color: black;
  padding-left: 10px;
  margin-top: 10px;
}

/* Default */

.hidden {
  display: none !important;
}

.invisible {
  display: none !important;
}

.no-scroll {
  overflow: visible;
}

.isChecked,
.isNotChecked {
  text-indent: -19px !important;
  padding-left: 19px !important;
  box-sizing: border-box;
}

.isChecked:before {
  font-family: 'Zukunft-Icons';
  content: '✓';
  color: #77CE52;
  padding-right: 3px;
}

.isNotChecked:before {
  font-family: 'Zukunft-Icons';
  content: '⮾';
  color: #ED193A;
  padding-right: 3px;
}

.history-back {
  margin-bottom: 30px;
  text-decoration: underline;
  cursor: pointer;
  display: block;
}

.history-back:before {
  font-family: 'Zukunft-Icons';
  content: '\2190\2009';
}

.history-back:hover {
  text-decoration: none;
}

.skip {
  margin-bottom: 30px;
  text-decoration: underline;
  cursor: pointer;
  display: block;
}

.skip:after {
  font-family: 'Zukunft-Icons';
  content: "\2192";
}

.skip:hover {
  text-decoration: none;
}

.button_box {
  color: white;
  background-color: #00af9b;
  padding: 10px 20px 10px 20px;
  margin: 5px 0px 10px 0px;
}

/* -------------------------- */

/* Color Frontend Layout */

.infocenter .current_page {
  color: #00af9b !important;
}

.infocenter > div:last-child,
.infocenter .mobile_header {
  background-color: #05454f;
  color: white;
}

.infocenter #shortcut_list a:hover {
  color: #00af9b !important;
}

.infocenter #menu2 a h3:hover {
  color: #00af9b !important;
}

.unterstuetzung_der_dgp .current_page {
  color: #006B7F !important;
}

.unterstuetzung_der_dgp > div:last-child,
.unterstuetzung_der_dgp .mobile_header {
  background-color: #d47143;
  color: white;
}

.unterstuetzung_der_dgp #shortcut_list a:hover {
  color: #006B7F !important;
}

.unterstuetzung_der_dgp #menu2 a h3:hover {
  color: #006B7F !important;
}

.fuer_partner .current_page {
  color: #004550 !important;
}

.fuer_partner > div:last-child,
.fuer_partner .mobile_header {
  background-color: #00959C;
  color: white;
}

.fuer_partner #shortcut_list a:hover {
  color: #004550 !important;
}

.fuer_partner #menu2 a h3:hover {
  color: #004550 !important;
}

.nachwuchs .current_page {
  color: #00af9b !important;
}

.nachwuchs > div:last-child,
.nachwuchs .mobile_header {
  background-color: rgba(240, 245, 246, 0.7);
  color: #3e3e3e;
}

.nachwuchs #menu2 li a h3 {
  color: #3e3e3e;
}

.nachwuchs #menu2 a h3:hover {
  color: #00af9b !important;
}

.white {
  color: white;
}

.black {
  color: black;
}

/*ckeditor remove tab*/

#p16 .cke_dialog_tab[title="Target"] {
  display: none;
}

/* ------------------------- */

/* Menu Elements */

.submenu {
  overflow: visible;
  position: relative;
  box-sizing: border-box;
  line-height: 1.6em;
}

.submenu > p {
  margin: 0;
}

.submenu > h2 {
  color: white;
  padding-bottom: 20px;
}

.submenu h3 {
  font-size: 24px !important;
}

#menu2 {
  padding: 0px;
  margin: 0px;
}

#menu2 li:not(:last-child) {
  margin-bottom: 5px;
}

#menu2 li a h3 {
  color: white;
  font-size: 30px;
}

#open_menu_container {
  float: right;
  line-height: 42px;
}

#close_menu_container {
  float: right;
}

.menu {
  z-index: 2000;
  background-color: white;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
}

.menu .menu_container {
  padding: 70px 30px 10px 30px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  flex: auto;
}

.menu .menu_container li:not(:last-child),
.menu .menu_container h2 {
  margin-bottom: 11px;
}

.menu .menu_container h3 {
  font-size: 24px !important;
  color: black !important;
}

.menu .menu_container h3:hover {
  color: #00af9b !important;
}

.menu #footer {
  position: initial;
}

.menu #footer li:last-child {
  display: none;
}

.wrapper .menu_bar {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: rgba(226, 235, 238, 0.5);
}

.menu_bar {
  padding: 10px 30px 10px 30px;
}

.menu_bar > * {
  display: inline-block;
}

.menu_title {
  max-width: 350px;
}

.menu_title a {
  text-decoration: none;
  color: black;
}

.menu_title a > * {
  display: inline-block;
  vertical-align: middle;
}

.menu_title img {
  height: 40px;
}

.menu_title p {
  margin: 7px 0px 0px 15px;
  font-family: 'BlissProReg';
  font-size: 20px;
}

.menu_title p span {
  font-size: 30px;
  font-family: 'BlissProExtraLight';
}

.open_menu {
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.open_menu h2 {
  color: #3e3e3e;
}

.hamburger {
  width: 26px;
  height: 3px;
  margin: 4px 12px 4px 0px;
  background-color: #00959C;
}

.close_menu img {
  padding-top: 5px;
  width: 38px;
}

.menu3 {
  padding-bottom: 15px;
}

.menu3 .quarter_column > div {
  max-width: 336px;
  margin-right: 15px;
}

.menu3 h1,
.menu3 h2,
.menu3 p,
.menu3 a {
  color: white !important;
}

.menu3 h1 {
  font-size: 30px;
  margin-bottom: 15px;
}

.menu3 > div:first-child p:not(:last-child) {
  margin-bottom: 35px !important;
}

/* ------------------------------ */

/* Footer */

.half_column #footer,
.three_quarter_column #footer {
  display: none;
}

#footer {
  position: absolute;
  bottom: 0;
  background-color: #E2EBEE;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

#footer ul {
  margin: 0;
  /*align list element*/
  /*height: 33px;*/
  padding-left: 30px;
  padding-right: 30px;
  line-height: 33px;
  vertical-align: middle;
  overflow: hidden;
}

#footer li {
  padding-right: 30px;
  padding-bottom: 0px;
  display: inline-block;
}

#footer li:last-child {
  float: right;
  padding-right: 0;
}

#footer li:last-child:after {
  font-family: 'Zukunft-Icons';
  content: '\2303';
}

#footer li img {
  height: 23px;
  vertical-align: middle;
  padding-bottom: 4px;
}

/* ------------------------------- */

/* Page Content Left Side */

.page_content {
  margin: 0px 30px 25px 30px;
  /*p {
    /*padding-bottom: 25px;
  }*/
}

.page_content > *:not(img) {
  max-width: 630px;
}

.page_content > img {
  max-width: 736px;
}

.page_content h1,
.page_content h2,
.page_content h3,
.page_content h4,
.page_content h5,
.page_content h6,
.page_content h7 {
  margin: 0;
  padding-bottom: 7px;
}

.page_title {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 15px;
  max-width: 1200px;
}

.page_content_large {
  margin: 0px 30px 0px 30px;
  max-width: 736px;
}

.page_content_xl {
  margin: 0px 30px 0px 30px;
  max-width: 1200px;
  clear: both;
}

/* ---------------------------------- */

/* For special contents
 * like citations, link lists...
 */

.special_box {
  position: relative;
  overflow: auto;
}

.special_line {
  position: absolute;
  width: 40px;
  height: 100%;
}

.special_line > img {
  width: 100%;
}

.special_line > .citation_icon:before {
  font-family: 'Zukunft-Icons';
  font-size: 38px;
  color: #d47143;
  content: '\2760';
}

.special_line > .shortly_said_icon:before {
  font-family: 'Zukunft-Icons';
  font-size: 38px;
  color: #d47143;
  content: '\1f5cf';
}

.special_line > .link_list_icon:before {
  font-family: 'Zukunft-Icons';
  font-size: 38px;
  color: #d47143;
  content: '\1f517';
}

.special_line > div {
  position: absolute;
  top: 45px;
  bottom: 3px;
  left: 16px;
  width: 2.5px;
  background-color: #d47143;
}

.special_main {
  margin-left: 40px;
  padding-left: 5px;
  padding-top: 10px;
  overflow: hidden;
}

.special_main .link_list_content > * {
  text-indent: -20px;
  margin-left: 20px;
}

.img_header img {
  vertical-align: middle;
  margin-bottom: 6px;
  padding-right: 5px;
}

/* ------------------------------ */

/* Shortcut list */

#shortcut_list {
  overflow: visible;
  margin: 0px 0px 0px 26px;
}

#shortcut_list li {
  margin-bottom: 4px;
  text-indent: -1.5em;
}

#shortcut_list li:first-child {
  margin-bottom: 15px;
}

#shortcut_list h7 {
  color: white;
}

#shortcut_list a {
  color: white;
}

#shortcut_list a:before {
  content: "\2192";
  font-family: 'Zukunft-Icons';
  color: #00af9b;
  margin-right: 7px;
}

/* ----------------------------------- */

/* Homepage */

.homepage_container {
  height: 100%;
  position: relative;
}

.homepage_container p {
  margin: 0;
  font-size: 17px;
}

.homepage_container > div > a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 15px));
  width: 230px;
  height: 50px;
  text-align: center;
  color: black;
}

.homepage_container > div > a > div {
  padding: 10px;
  background-color: white;
  transform: scale(1);
  transition: all .2s ease-in-out;
}

.homepage_container > div > a > div:hover {
  transform: scale(1.1) perspective(1px);
}

.homepage_container > div > a > div > p:first-child {
  font-family: 'BlissProExtraLight';
  font-size: 26px;
  font-weight: normal;
}

.homepage_container > div:nth-child(2) {
  background-color: #004550;
}

.homepage_container > div:nth-child(3) {
  background-color: #d47143;
}

.homepage_container > div:nth-child(1) > a > div {
  border: solid #053875 1px;
}

.homepage_container > div:nth-child(2) > a > div {
  border: solid #5799A3 1px;
}

.homepage_container > div:nth-child(3) > a > div {
  border: solid #F4B99B 1px;
}

.homepage_container > div:nth-child(4) > a > div {
  border: solid #00af9b 1px;
}

/* ------------------------------- */

/* Für Partner/ Anmeldung Theme */

.welcome_text {
  margin-bottom: 40px;
}

.welcome_text h4 {
  margin: 0;
}

.welcome_text p {
  margin: 0;
}

.welcome_text a:after {
  font-family: 'Zukunft-Icons';
  content: '\2009\2192';
}

.approval_container {
  background-color: rgba(0, 175, 155, 0.1);
  padding: 10px 10px 10px 10px;
}

.approved {
  background-color: #EDFBED !important;
}

/* -------------------------------- */

/* Login Form Container */

input#username,
input#password {
  border: solid 1px white;
  background-color: #00959C;
  color: white;
}

input#username::placeholder,
input#password::placeholder {
  color: white;
}

/* Change Autocomplete styles in Chrome Safari*/

input#username:-webkit-autofill,
input#password:-webkit-autofill,
input#username:-webkit-autofill:hover,
input#password:hover,
input#username:-webkit-autofill:focus,
input#password:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #00959C inset;
}

input#registration_email:-webkit-autofill,
input#registration_plainPassword_first:-webkit-autofill,
input#registration_plainPassword_second:-webkit-autofill,
input#registration_email:hover,
input#registration_plainPassword_first:hover,
input#registration_plainPassword_second:hover,
input#registration_email:focus,
input#registration_plainPassword_first:focus,
input#registration_plainPassword_second:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.login_container button[type="submit"] {
  background-color: white;
  color: #00959C;
}

.login_container button[type="submit"]:hover {
  color: white;
  background-color: #004550 !important;
}

.login_container a {
  line-height: 40px;
  margin-top: 10px;
  color: white;
}

/* -------------------------------- */

/* Registration Form Container */

#registration button[type="submit"] {
  margin-top: 20px;
}

#registration input {
  max-width: 330px;
}

#registration input::placeholder {
  color: black;
}

#registration #konditionen {
  margin-top: 20px;
  text-indent: -26px;
  padding-left: 26px;
}

#registration #konditionen > div:not(:first-child) {
  padding-top: 10px;
}

#registration #konditionen label {
  font-family: 'BlissProLightItalic';
  vertical-align: top;
  font-size: 15px;
}

#registrierung p:first-child {
  font-family: 'BlissProLight';
  font-size: 20px;
  color: #05454f;
}

.registrated {
  color: white;
}

.registrated .login_container input {
  width: 100% !important;
}

/* -------------------------------- */

/* Account Data Form Container */

#account_data button[type="submit"] {
  margin-bottom: 10px;
}

#account_data label {
  font-family: 'BlissProMedium';
  font-size: 18px;
}

#account_data input {
  max-width: 330px;
  margin-top: 3px;
  margin-bottom: 18px;
}

#account_data input[readonly="readonly"] {
  opacity: 0.7;
}

/* -------------------------------- */

/* Dropdown Design */

.dropdown {
  border: 1px solid #00AF9B;
  vertical-align: top;
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding: 0;
}

.dropdown li {
  margin: 0;
  padding: 0 5px 0 5px;
  background-color: white;
  text-decoration: none;
  list-style: none;
  height: 37px;
  line-height: 37px;
  position: relative;
  display: none;
}

.dropdown.opened li {
  display: block;
}

.dropdown li.selected_el {
  display: block;
}

.dropdown li.selected_el:after {
  font-family: 'Zukunft-Icons';
  content: "\2304\2009";
  text-align: right;
  float: right;
  color: #00af9b;
  width: 18px;
}

.dropdown.opened li.selected_el:after {
  font-family: 'Zukunft-Icons';
  content: "\2303";
  color: #00af9b;
}

.dropdown .list_box {
  max-height: 200px;
  overflow-y: scroll;
  margin-left: -1px;
  margin-right: -1px;
  display: none;
}

.dropdown.opened .list_box {
  border: 1px solid #00af9b;
  position: relative;
  z-index: 100;
  cursor: pointer;
}

.dropdown .list_box li {
  z-index: 100;
}

/* -------------------------------- */

/* Institution reset Passwort */

#reset_password input {
  max-width: 330px;
}

/* ----------------------------- */

/* Offer */

.active_offer,
.inactive_offer {
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  border-radius: 11px;
  padding: 0px 5px 0px 5px;
  cursor: pointer;
}

.active_offer {
  background-color: #77CE52;
  color: white;
  font-size: 15px;
  letter-spacing: 0.01em;
}

.active_offer:hover {
  background-color: #A9E68F;
}

.active_offer:before {
  font-family: 'Zukunft-Icons';
  content: '\1F441';
  padding-right: 2px;
}

.inactive_offer {
  background-color: #7C7C7C;
  color: white;
  font-size: 15px;
  letter-spacing: 0.01em;
}

.inactive_offer:hover {
  background-color: #AFAFAF;
}

.inactive_offer:before {
  font-family: 'Zukunft-Icons';
  content: '\E001';
  padding-right: 2px;
}

.down_active_offer {
  font-size: 18px;
  letter-spacing: 0.01em;
}

/*.down_active_offer:hover {
  background-color: #A9E68F;
}*/

.down_active_offer:before {
  font-family: 'Zukunft-Icons';
  cursor: pointer;
  content: '\E001';
  padding-right: 2px;
}

.down_inactive_offer {
  font-size: 18px;
  letter-spacing: 0.01em;
}

/*.down_inactive_offer:hover {
  background-color: #AFAFAF;
}*/

.down_inactive_offer:before {
  font-family: 'Zukunft-Icons';
  cursor: pointer;
  content: '\1F441';
  padding-right: 2px;
}

.created_date {
  padding: 8px 10px 1px 5px;
  font-family: 'BlissProLight';
  font-size: 15px;
  letter-spacing: 0.03em;
}

ul.offer-list {
  margin-bottom: 0;
}

ul.offer-list hr {
  background-color: #E2EBEE;
  height: 9px;
  border: none;
  margin: 0 -30px;
}

ul.offer-list div {
  overflow: hidden;
}

ul.offer-list li {
  padding: 0px 0px 10px 0px;
}

ul.offer-list li .offer-info {
  padding: 0px 0px 10px 0px;
}

ul.offer-list li .offer-info > div {
  padding-top: 10px;
}

ul.offer-list li .created_date {
  display: inline-block;
}

ul.offer-list h4 {
  margin: 0px;
  padding: 0px 0px 10px 0px;
}

ul.offer-list .offer-edit a:not(:first-child) {
  padding-left: 20px;
}

.dateTypeForm > div {
  display: inline-block;
  vertical-align: middle;
}

.dateTypeForm > div:nth-child(1) {
  width: 136px;
}

.dateTypeForm > div:nth-child(2) {
  width: 55px;
}

.dateTypeForm > div:nth-child(3) {
  width: 71px;
}

.dateTypeForm > div:nth-child(4) {
  width: 75px;
}

.dateTypeForm .dropdown {
  border: 1px solid #3e3e3e;
}

.dateTypeForm .dropdown li.selected_el:after {
  color: #3e3e3e;
}

.dateTypeForm .dropdown.opened li.selected_el:after {
  color: #3e3e3e;
}

.dateTypeForm .dropdown.opened .list_box {
  border: 1px solid #3e3e3e;
}

form[name="edit_offer"] {
  clear: both;
}

form[name="edit_offer"] > div {
  margin: 20px 0px 10px 0px;
}

form[name="edit_offer"] > div:last-child {
  margin: 0px;
  text-align: right;
}

form[name="edit_offer"] input[type="text"],
form[name="edit_offer"] input[type="email"] {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  font-size: 18px;
  font-family: 'BlissProReg';
  padding: 0px 10px 0px 10px;
  border: 1px solid #00AF9B;
}

form[name="edit_offer"] label {
  font-family: 'BlissProMedium';
  font-size: 18px;
}

form[name="edit_offer"] .inline-block.xs_width > div:first-child {
  width: 136px;
}

form[name="edit_offer"] .inline-block.xs_width > div:last-child {
  max-width: 433px;
  width: 100%;
}

form[name="edit_offer"] #arbeitsart > div {
  margin: 15px 0px 0px 26px;
  text-indent: -26px;
}

form[name="edit_offer"] #spezialisierung {
  margin-bottom: 40px;
}

form[name="edit_offer"] #spezialisierung > div {
  margin: 15px 0px 0px 0px;
}

form[name="edit_offer"] h4 {
  padding-top: 20px;
}

form[name="edit_offer"] .flex-cell {
  display: flex;
  align-items: center;
}

form[name="edit_offer"] .flex-cell > div:first-child {
  min-width: 130px;
}

form[name="edit_offer"] .flex-cell > div:last-child {
  width: 100%;
}

/* ------------------------------ */

/* CKE Styling */

.cke_bottom {
  display: none !important;
}

.cke_top {
  border-bottom-color: #00af9b !important;
}

.cke {
  border-top-color: #00af9b !important;
  border-right-color: #00af9b !important;
  border-bottom-color: #00af9b !important;
  border-left-color: #00af9b !important;
}

ul.colored_list {
  list-style-position: inside;
  padding-left: 5px;
  display: table;
}

ul.colored_list li {
  display: table-row;
}

ul.colored_list li:before {
  content: "\2022";
  color: #00af9b;
  font-weight: bold;
  padding-right: 5px;
  display: table-cell;
}

ul.colored_list li > * {
  display: initial;
  display: inline-flex;
}

ol.colored_list {
  counter-reset: li;
  padding-left: 5px;
  display: table;
}

ol.colored_list li {
  counter-increment: li;
  display: table-row;
  list-style-position: outside;
}

ol.colored_list li:before {
  content: counter(li) ".";
  color: #00af9b;
  padding-right: 5px;
  display: table-cell;
}

ol.colored_list li > * {
  display: initial;
  display: inline-flex;
}

.inline_anchor {
  text-decoration: underline;
}

.inline_anchor:hover {
  text-decoration: none;
}

.internal_link:after {
  font-family: 'Zukunft-Icons';
  font-weight: normal;
  content: '\2005\2192';
  text-decoration: none !important;
}

.external_link:before {
  font-family: 'DGPIcons';
  font-weight: normal;
  content: "\2197\2005";
  text-decoration: none !important;
}

/* ------------------------------- */

/* Change / New Offer */

.offer-type-list {
  /*li {
  }*/
  /*li a h7 {
  }*/
}

.offer-type-list li h7 {
  display: inline-block;
}

.offer-type-list li a p {
  display: inline-block;
}

.offer-type-list li a p:last-child:after {
  font-family: 'Zukunft-Icons';
  content: '\2192';
}

/* ------------------------------- */

/* Specific Offer */

.offer_bar {
  margin: 0px 0px 0px 0px;
}

.offer_bar > div {
  padding: 10px 0px 0px 0px;
}

.offer_bar .created_date {
  display: inline-block;
}

.institution_info_benefits .three_column > * {
  display: inline-block;
  vertical-align: top;
  width: 32%;
}

.institution_info_benefits,
.institution_info_work_culture {
  margin: 0px 0px 30px 0px;
}

.institution_info,
.offer_info {
  /*.offer_description {
    //padding-bottom: 20px;
  }*/
}

.institution_info p,
.offer_info p {
  margin-bottom: 9px;
}

.institution_info h4,
.offer_info h4 {
  padding-top: 0px;
  margin: 20px 0px 16px 0px;
}

.institution_info span,
.offer_info span {
  font-family: 'BlissProMedium';
}

.institution_info .offer_title,
.offer_info .offer_title {
  font-size: 26px;
}

.institution_info .offer_department > *,
.institution_info .offer_contact > *,
.institution_info .offer_link > *,
.offer_info .offer_department > *,
.offer_info .offer_contact > *,
.offer_info .offer_link > * {
  display: inline-block;
}

.institution_info .offer_department h4,
.institution_info .offer_department p,
.institution_info .offer_contact h4,
.institution_info .offer_contact p,
.institution_info .offer_link h4,
.institution_info .offer_link p,
.offer_info .offer_department h4,
.offer_info .offer_department p,
.offer_info .offer_contact h4,
.offer_info .offer_contact p,
.offer_info .offer_link h4,
.offer_info .offer_link p {
  padding-right: 5px;
  margin-bottom: 0px;
}

.offer_info {
  padding-bottom: 20px;
}

/* --------------------------------- */

/* Edit Institution Rahmenbedingung */

#institution_rahmenbedingung input[type="text"] {
  width: 100%;
  margin-top: 5px;
}

#institution_rahmenbedingung input[type="radio"] + label {
  font-family: 'BlissProLight';
}

#institution_rahmenbedingung textarea {
  width: 100%;
  height: 200px;
  margin-top: 0;
  padding: 10px;
  margin-top: 5px;
}

#institution_rahmenbedingung label {
  font-family: 'BlissProMedium';
  font-size: 18px;
}

#institution_rahmenbedingung h4 {
  padding: 0px 0px 16px 0px;
}

#institution_rahmenbedingung p {
  padding: 0px 0px 14px 0px;
}

#institution_rahmenbedingung span {
  font-family: 'BlissProMedium';
}

#institution_rahmenbedingung .pad {
  padding-bottom: 15px;
}

#institution_rahmenbedingung .inline-block > div {
  vertical-align: top;
  width: 47%;
}

#institution_rahmenbedingung .inline-block > div:first-child {
  margin-right: 4.6%;
}

#institution_rahmenbedingung #institution_type label {
  margin-top: 15px;
}

#institution_rahmenbedingung #institution_bedNumber {
  padding-left: 40px;
}

#institution_rahmenbedingung #institution_bedNumber label:not(:last-child) {
  margin-bottom: 15px;
}

#institution_rahmenbedingung .inline-block > div > div {
  padding-bottom: 15px;
}

#institution_rahmenbedingung #institution_type_5 + label {
  display: table-cell;
  width: 120px;
}

#institution_rahmenbedingung #institution_sonstiges {
  display: table-cell;
}

#institution_rahmenbedingung #institution_type {
  padding-bottom: 20px;
}

#institution_rahmenbedingung #organisatorisches,
#institution_rahmenbedingung #institutionsgroesse,
#institution_rahmenbedingung #familie_und_karriere {
  padding: 0px 0px 47px 0px;
}

#institution_rahmenbedingung #organisatorisches p:last-child,
#institution_rahmenbedingung #institutionsgroesse p:last-child,
#institution_rahmenbedingung #familie_und_karriere p:last-child {
  padding: 0px 0px 0px 0px;
}

#institution_rahmenbedingung .table-cell {
  width: 100%;
  display: table;
}

#institution_rahmenbedingung .table-cell > div {
  display: table-cell;
  width: 50%;
  padding-top: 15px;
}

#institution_rahmenbedingung .table-cell > div:first-child {
  border-right: 10px solid transparent;
}

#institution_rahmenbedingung #institution_equalityCommission_ja,
#institution_rahmenbedingung #institution_equalityCommission_nein {
  margin-top: 10px;
}

#institution_rahmenbedingung #institution_parentalLeave {
  padding-top: 20px;
}

#institution_rahmenbedingung #cke_institution_description {
  margin-top: 5px;
}

.institution_partition {
  text-align: right;
  margin-top: 15px;
}

.institution_partition > * {
  display: inline-block;
}

.institution_partition h1 {
  color: #00AF9B;
  vertical-align: middle;
}

.institution_partition input,
.institution_partition button,
.institution_partition .button_box {
  margin-left: 50px;
}

#mobile_pad_indication {
  display: none;
  padding: 10px 0px 0px 0px;
}

#mobile_pad_indication h3 {
  line-height: 30px;
  font-size: 17px;
  text-align: center;
  color: #004550;
}

#mobile_pad_indication h3:after {
  content: url("../logos/quer.svg");
  vertical-align: middle;
}

#institution_benefit h7 {
  color: white;
  padding-left: 6px;
  padding-right: 6px;
  display: block;
  line-height: 25px;
}

/* ---------------------------------- */

/* edit offer bar */

.edit_offer_bar > div {
  padding-top: 10px;
}

.edit_offer_bar h7[class$="-background-color"] {
  display: inline-block;
}

#cke_edit_offer_description {
  margin-top: 5px;
}

/* --------------------------------- */

.small_info a {
  display: none;
}

.mobile_header {
  display: none;
}

.mobile_header > div {
  color: white;
}

.mobile_header h2 {
  padding-bottom: 15px;
}

.mobile_header h7 {
  padding-bottom: 10px;
  display: inline-block;
}

.form-expanded-dropdown label {
  display: block;
  padding-bottom: 15px;
}

.department_list {
  padding-bottom: 10px;
}

#meine_angebote {
  flex: 100;
  overflow: visible;
}

