$dark_green: #004550;
$medium_green: #006B7F;
$dark_light_green: #00959C;
$light_green: #00af9b;
$teal: #05454f;
$grey: #3e3e3e;
$blue: #053875;
$orange: #d47143;
$beige: rgba(240, 245, 246, 0.7);

$wait_approval: rgba(0, 175, 155, 0.1);
$approved: #EDFBED;

$red_error: #ED193A;
$pink_error: #FA98A8;

$hovered: rgba(244,247,249, 0.3);


$color_pflegepraktikum: #00AF9B;
$color_famulatur: #64BEC3;
$color_praktisches_jahr: #336A73;
$color_assistent\/assistentin-in-weiterbildung: #ED193A;

$color_schwerpunkt_pneumologie: #ED193A;
$color_pneumologe: #6687AD;

$color_oberarzt\/Ã¤rztin: #436A97;
$color_chefarzt\/Ã¤rztin: #053875;
$color_facharzt\/Ã¤rztin: #819BB9;
$color_wissenschaftliche-mitarbeitende: #7C7C7C;
$color_atmungstherapeuten: #3E3E3E;
$color_sonstige: #C1C1C1;
$color_weiterbildungsbefugte: #F26821;

%color_container {
  color: white;
  padding: 2px 5px 0px 5px !important;
  background-color: black;
  margin: 5px 5px 5px 0px !important;
  height: 22px;
  white-space: nowrap;
}


.pflegepraktikum-background-color {
  @extend %color_container;
  background-color: $color_pflegepraktikum;
}
.famulatur-background-color {
  @extend %color_container;
  background-color: $color_famulatur;
}
.praktisches-jahr-background-color {
  @extend %color_container;
  background-color: $color_praktisches_jahr;
}
.assistent\/assistentin-in-weiterbildung-background-color {
  @extend %color_container;
  background-color: $color_assistent\/assistentin-in-weiterbildung;
}
.oberarzt\/Ã¤rztin-background-color {
  @extend %color_container;
  background-color: $color_oberarzt\/Ã¤rztin;
}
.chefarzt\/Ã¤rztin-background-color {
  @extend %color_container;
  background-color: $color_chefarzt\/Ã¤rztin;
}
.facharzt\/Ã¤rztin-background-color {
  @extend %color_container;
  background-color: $color_facharzt\/Ã¤rztin;
}
.wissenschaftliche-mitarbeitende-background-color {
  @extend %color_container;
  background-color: $color_wissenschaftliche-mitarbeitende;
}
.atmungstherapeuten-background-color {
  @extend %color_container;
  background-color: $color_atmungstherapeuten;

}
.sonstige-background-color {
  @extend %color_container;
  background-color: $color_sonstige;
  color: black !important;
}

.landesÃ¤rztekammer-background-color {
  @extend %color_container;
  color: black !important;
  background-color: white;
  border: 1px solid black;
}

.weiterbildungsbefugte-background-color {
  @extend %color_container;
  background-color: $color_weiterbildungsbefugte;
}

.pflegepraktikum-color {
  color: $color_pflegepraktikum;
}
.famulatur-color {
  color: $color_famulatur;
}
.praktisches-jahr-color {
  color: $color_praktisches_jahr;
}
.assistent\/assistentin-in-weiterbildung-color {
  color: $color_assistent\/assistentin-in-weiterbildung;
}
.oberarzt\/Ã¤rztin-color {
  color: $color_oberarzt\/Ã¤rztin;
}
.chefarzt\/Ã¤rztin-color {
  color: $color_chefarzt\/Ã¤rztin;
}
.facharzt\/Ã¤rztin-color {
  color: $color_facharzt\/Ã¤rztin;
}
.wissenschaftliche-mitarbeitende-color {
  color: $color_wissenschaftliche-mitarbeitende;
}
.atmungstherapeuten-color {
  color: $color_atmungstherapeuten;
}
.sonstige-color {
  color: $color-sonstige;
}
.weiterbildungsbefugte-color {
  color: $color_weiterbildungsbefugte;
}
.orange {
  color: $orange !important;
}
.teal {
  color: $teal !important;
}
.blue {
  color: $blue !important;
}
.light_green {
  color: $light_green !important;
}
.grey {
  color: $grey !important;
}