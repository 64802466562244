@import './_colors';

.invalid_input {
  border: 1px solid $red_error !important;
}
label.invalid_input {
  border: 1px solid transparent !important;
  color: $red_error;
  span {

  }
}
#registration .invalid_input + input {
  border: 1px solid $red_error !important;
}
#registration_plainPassword_second {
  margin-bottom: 10px;
}
.form_error {
  margin: 2px 0px 2px 0px;
  color: $red_error;
  li {
    margin: 0px;
    padding: 0px;
  }
  li:before {
    background-image: url("_logos/exclamation.svg");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    padding-right: 20px;// equal to width of image.
    margin-right:5px;// to add some space for better look
    content:"";
  }
}

.login_error {
  max-width: 464px;
  margin: 0px;
  color: white;
  li {
    margin: 0px;
    padding: 0px;
  }
  li:before {
    background-image: url("_logos/exclamation_white.svg");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    padding-right: 20px;// equal to width of image.
    margin-right:5px;// to add some space for better look
    content:"";
    fill: white;
  }
}
.invalid_login_input {
  border: 1px solid $pink_error !important;
}

.dropdown.invalid_dropdown {
  border: 1px solid $red_error !important;
  li.selected_el:after {
    color: $red_error !important;
  }
  &.opened li.selected_el:after {
    color: $red_error !important;
  }

  &.opened .list_box {
    border: 1px solid $red_error !important;
  }
}

.slider-men, .slider-women {
  .rc-slider {
    > div {
      margin-left: 5px;
    }
  }
}