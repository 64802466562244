.toggle-wrapper {
  overflow: auto;

  h4 {
    cursor: pointer;

    &:after {
      margin-left: 5px;
    }
  }

  &.toggle-closed .toggle-content{
    display: none;
  }

  &.toggle-open h4:after {
    font-family: Zukunft-Icons;
    content: "\2303"
  }

  &.toggle-closed h4:after {
    font-family: Zukunft-Icons;
    content: "\2304";
  }

  .toggle-content {
    overflow: auto;
  }
}