@import './sizes';

$page_content_padding_mobile_lr: 15px;
$page_content_padding_mobile_bottom: 30px;
$page_padding_mobile_top: 20px;

@media (max-width: $pad-width) {
  .homepage_container {
    min-height: 100%;

    .quarter_column {
      width: 50%;
      min-height: 50%;
      height: 100px;
    }
  }

  .menu_container {
    > .half_column {
      .half_column {
        width: 100%;
        float: none;
        margin-bottom: 30px;
      }
    }
    .half_column {
      height: auto;
    }
  }

  div:not(.menu) > .menu_bar {
    background-color: white;
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    height: 40px;
    width: 110px;
    padding: 10px;
    border-bottom: unset !important;

    .menu_title {
      display: none;
    }

  }

  #content {
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }

  #content {
    &.filled {
      .quarter_column, .three_quarter_column {
        width: 100%;
        height: auto;
        padding-bottom: unset;

        .small_info {
          max-width: unset;
        }
      }
    }

    &.infocenter, &.unterstuetzung_der_dgp, &.fuer_partner, &.nachwuchs {
      > div:last-child {
        display: none;
      }

      > .half_column {
        width: 100%;
      }
    }

    .half_column:nth-child(1) {
      padding-top: 0 !important;
    }

    .mobile_header {
      display: block;
      width: 100%;
      margin-bottom: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 30px;
      padding-left: 30px;
      box-sizing: border-box;

      h2:first-child {
        padding-right: 80px;
      }
    }

    #mobile_pad_indication {
      display: block;
    }

  }

  .fuer_partner {
    > .three_quarter_column {
      width: 100%;
      padding-top: 0px !important;

      .page_content_xl {
        form {
          overflow-x: scroll;
        }
        table {
          display: block;
          overflow-x: scroll;
          padding-top: 0px;
        }
      }
    }
    > .quarter_column {
      display: none;
    }
  }

  #content.anmeldung {
    &.filled {
      .quarter_column {
        width: 50%;
      }
      .quarter_column.clear {
        clear: unset;
      }
    }
  }

  .quarter_column:after {
    padding-bottom: 0px !important;
  }

}


@media (max-width: $mobile-width) {
  .homepage_container {
    min-height: 100%;

    .quarter_column {
      width: 100%;
      min-height: 25%;
      height: 100px;
    }
  }

  #content:not(.anmeldung) {
    .half_column:nth-child(1) {
      display: flex;
      flex-direction: column;
    }
    .page_title {
      flex: 1;
    }
    .page_content, .page_content_large, .page_content_xl {
      flex: 10;
    }
  }

  .menu_container {
    > .half_column {
      width: 100%;
    }
  }

  .pneumo_span {
    display: block;
  }

  div:not(.menu) > .menu_bar {
    background-color: white;
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    padding: 10px;
    border-bottom: unset !important;

    .menu_title {
      display: none;
    }

    .open_menu h2 {
      display: none;
    }

  }
  .menu {
    .menu_bar {
      padding-left: $page_content_padding_mobile_lr;
      padding-right: $page_content_padding_mobile_lr;
    }
    .menu_title {
      img {
        height: 49px !important;
      }
    }
    .menu_container {
      position: initial !important;
      padding-left: $page_content_padding_mobile_lr !important;
      padding-right: $page_content_padding_mobile_lr !important;
      padding-top: 30px !important;

      h2 {
        margin-bottom: 5px !important;
      }
    }
    #footer {
      position: initial;
      bottom: 0px;
    }
  }

  .hamburger {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  #content {
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }

  body > #footer {
    display: none;
  }

  #footer {
    li {
      padding-right: 15px !important;
    }
  }

  .half_column, .three_quarter_column {
    #footer {
      display: block !important;
      position: initial;
      margin-top: 50px;
    }
  }
  .half_column, .three_quarter_column {
    &:after {
      padding-bottom: 0px !important;
    }
  }

  #institution_rahmenbedingung {
    .table-cell {
      > div {
        display: inline-block !important;
        border-right: none !important;
        width: 100% !important;
      }
    }
  }
  .institution_info_benefits .three_column > * {
    width: 49% !important;
  }
  #institution_rahmenbedingung {
    .inline-block {
      > div {
        width: 90% !important;
        &:first-child {
          margin-right: 0 !important;
        }
      }
    }
    #institution_bedNumber {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 0px !important;
    }
    #institution_equalityCommission_ja, #institution_equalityCommission_nein {
      padding-top: 5px;
    }
  }

  #content.anmeldung {
    padding-top: $page_padding_mobile_top;
    &.filled {
      .quarter_column {
        width: 100%;
      }
      > div > div:first-child {
        padding-bottom: 50px;
      }
      .quarter_column.clear {
        clear: unset;
      }
    }
  }
  #content:not(.anmeldung) {
    .quarter_column:first-child {
      padding-top: $page_padding_mobile_top !important;
    }
  }
  .anmeldung {
    padding-top: $third_level_page_padding_top;
    .quarter_column {
      padding-top: 0px !important;
    }
  }

  #angebote_der_dgp_toggle {
    display: block;
  }
  #angebote_der_dgp_toggle_text {
    display: none;
  }

  .page_content {
    padding-left: $page_content_padding_mobile_lr !important;
    padding-right: $page_content_padding_mobile_lr !important;
  }
  .page_title {
    padding-left: $page_content_padding_mobile_lr !important;
    padding-right: $page_content_padding_mobile_lr !important;
  }
  .page_content_large {
    padding-left: $page_content_padding_mobile_lr !important;
    padding-right: $page_content_padding_mobile_lr !important;

  }
  .page_content_xl {
    padding-left: $page_content_padding_mobile_lr !important;
    padding-right: $page_content_padding_mobile_lr !important;
  }

  .mobile_header {
    padding-left: $page_content_padding_mobile_lr !important;
    padding-right: $page_content_padding_mobile_lr !important;
  }
  .filled {
    padding-left: $page_content_padding_mobile_lr !important;
    padding-right: $page_content_padding_mobile_lr !important;
  }
  #footer {
    ul {
      padding-left: $page_content_padding_mobile_lr !important;
      padding-right: $page_content_padding_mobile_lr !important;
    }
  }
  ul.offer-list {
    hr {
      margin: 0 -15px !important;
    }
  }

  #content.anmeldung {
    padding-bottom: 20px !important;
  }
  #content:not(.anmeldung) {
    .half_column:nth-child(1) {
      padding-bottom: 0 !important;
    }
  }
  .three_quarter_column {
    padding-bottom: 0 !important;
  }
}

@media (max-width: $small_mobile_width) {
  .menu {
    .menu_title {
      p {
        font-size: 19px;
      }
      span {
        font-size: 23px;
      }
    }
    .close_menu {
      img {
        width: 30px;
      }
    }
  }
  #content.anmeldung {
    &.filled {
      .quarter_column {
        p {
          display: none;
        }
      }
    }
  }
}